import React from "react";
import SText from "./SText";
import RoundedButton from "./RoundedButton";
import notFound from "../assets/images/notFound.svg"
import notFoundMobile from "../assets/images/notFoundMobile.svg"
import styles from "./NotFound.module.css"
import Colors from "../utils/Colors";
import {useWindowDimensions} from "../utils/hooks";
import Header from "./Header";
import {NavLink} from "react-router-dom";

export default () => {

    const {windowDimensions: {width}} = useWindowDimensions()

    return <div>
        <Header/>
        <div className={styles.wrapper}>
            <div className={styles.image}><img src={width > 680 ? notFound : notFoundMobile}/></div>
            <div className={styles.text}><SText fontSize={width >= 680 ? 18 : 14} fontWeight={400}>Возможно, товар,
                который вы искали, был переименован. <br/>
                Пожалуйста, воспользуйтесь каталогом или поиском.</SText></div>
            <div className={styles.button}><NavLink to={"/"}><RoundedButton label={'На главную'} activeColor={Colors.mainGreen}/></NavLink></div>
        </div>
    </div>
}