import React from "react";
import styles from "./AgreementsBlock.module.css"
import Switch from "./Switch";
import {text} from "../utils/mixins";
import Colors from "../utils/Colors";
import SText from "./SText";

const AgreementsBlock = ({
                             isAgreed = false, setAgreed = () => {
    }
                         }) => {
    return <div className={styles.container}>
        <div className={styles.adsBlock}>
            <SText  {...text(11, 700, 2, false, Colors.grayFont)} style={{flex: '1'}}>
                {'Я даю '}
                <SText {...text(11, 700, 2, false, Colors.mainGreen)}>
                    {'согласие '}
                </SText>
                {'на получение рекламных сообщений'}
            </SText>
            <Switch isOn={isAgreed} onToggle={setAgreed}/>
        </div>
        <div className={styles.policyBlock}>
            <SText lineHeight={11} {...text(11, 700, 2, false, Colors.grayFont)}>
                {'Нажимая "Отправить СМС", я соглашаюсь с '}
                <SText lineHeight={11} {...text(11, 700, 2, false, Colors.mainGreen)}>
                    {'Условиями продажи'}
                </SText>
                {', '}
                <SText lineHeight={11} {...text(11, 700, 2, false, Colors.mainGreen)}>
                    {'Политикой конфиденциальности'}
                </SText>
                {' и '}
                <SText lineHeight={11} {...text(11, 700, 2, false, Colors.mainGreen)}>
                    {'Политикой в отношении обработки персональных'}
                </SText><br/>
                <SText lineHeight={11} {...text(11, 700, 2, false, Colors.mainGreen)}>
                    {' данных'}
                </SText>
                {'.'}
            </SText>
        </div>
    </div>
}

export default AgreementsBlock