import React, {useState} from 'react'

const shape = {
    auth: false,
    product: false,
    user: false,
    close: (modalName)=>{},
    open: (modalName)=>{},
}
const ModalContext = React.createContext(shape)

export function useModalContext () {
    const [state, _setState] = useState(shape)

    function open (modalName, specificValue=true) {
        _setState(prev=>({...prev, [modalName]: specificValue}))
    }

    function close (modalName) {
        _setState(prev=>({...prev, [modalName]: false}))
    }

    return {...state, open, close}
}

export default ModalContext
