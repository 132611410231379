import Colors from "./Colors";

function getFontByThickness(thickness) {
    switch (thickness) {
        case 0:
            return 'HelveticaNeueCyr-Roman, sans-serif'
        case 1:
            return 'HelveticaNeueCyr-Medium, sans-serif'
        case 2:
            return 'HelveticaNeueCyr-Bold, sans-serif'
        case 3:
            return 'HelveticaNeueCyr-Black, sans-serif'
        default:
            return 'HelveticaNeueCyr-Medium, sans-serif'
    }
}

export function text(fontSize = 14, fontWeight = 400, fontType = 1, largerGap = false, color = Colors.darkBlue, lineHeight) {
    let type = 0
    if (fontWeight >= 500) {
        type = 1
        if (fontWeight >= 700)
            type = 2
        if (fontWeight >= 900)
            type = 3
    }
    return {
        fontFamily: getFontByThickness(type),
        fontSize: fontSize + 'px',
        color: color,
        lineHeight: lineHeight ? lineHeight + 'px' : largerGap ? '120%' : '100%',
    }
}

export function circle (size = 26, color = '#f5f5f5', grow=false) {
    return {
        width: grow ? undefined :size,
        minWidth: grow ? size : undefined,
        height: size,
        borderRadius: size / 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: color
    }
}
