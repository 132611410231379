export const BASE_URL = 'https://api.seasonmarket.ru/api'
export const DOMAIN_URL = 'https://api.seasonmarket.ru/'

export const MapKitKeys = [
  '95f79f13-83ec-42b6-890b-db1b8b823e92',
  '329a49e2-df63-442e-b4d1-e74b00614e8a',
]

export const GeocoderKeys = [
  '24495f21-70af-4b3c-ac1b-8a23d30ae9ab',
  '486bc32f-60f4-4320-9d61-c8d14935e017',
  'c0e4e3d2-913b-4873-a81a-59ea899446f8',
  '64f61949-4219-4c76-9c63-a76af46cb444',
  'a45fc414-5133-407b-a440-91d028095f30',
  'ddb478df-6120-46a8-8331-ea67cefec68c',
  '7020eded-f424-41c9-a1ee-3b900407ec1d',
  '6eaae1b5-1658-4231-ade6-bdd2115e57fc',
  'c0ca7106-4fd8-4945-a109-589f3178b809',
  'a8c399eb-dae8-4021-ac7b-f33c62692f79',
  '708189d3-6779-425c-9e9d-1dc5676a1889',
  '422562a5-3b95-4f0e-bf4a-8a81827d0475',
  'f7864d55-db9a-4e03-bb7a-36426a68ec07',
  '87799ff0-61be-4f2a-ab78-19e266a6615e',
  'a9e60283-2fdf-4780-a2dd-d263814d52e6'
]

export function getRandomMapKey() {
  return MapKitKeys[1]

  const index = Math.round(Math.random()) * (MapKitKeys.length - 1)
  return MapKitKeys[index]
}

export const addressSearchBounds = '29.327057,60.243699~39.589145,54.931679'

export const SupportPhoneNumber = '+7 495 215-51-44'

export const SupportWANumber = '+7 495 215-51-44'


export const inputFormat = 'YYYY-MM-DD HH:mm:SS'
export const targetFormat = 'HH:mm'
export const dayFormat = 'LL'
