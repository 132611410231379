import React, {useRef, useState} from "react";
import Slider from "react-slick";
import Card from "../screens/Catalogue/components/Card";
import left from "../assets/images/bannerButtonLeft.svg";
import right from "../assets/images/bannerButtonRight.svg";
import styles from "./SimilarProductsCarousel.module.css"
import {useWindowDimensions} from "../utils/hooks";
import {ProductCard} from "./ProductCard";

export default ({slidesToShow, categories}) => {


    const settings = {
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: slidesToShow,
        lazyLoad: false,
        arrows: false,
        slidesToScroll: 1,

    };

    const slider = useRef(null)
    const [cantNext, setCantNext] = useState(categories.length - 4)

    const {windowDimensions: {width}} = useWindowDimensions()

    function next() {
        if (cantNext > 0) {
            slider.current.slickNext()
            setCantNext(prev => prev - 1)
        }
    }

    function prev() {
        slider.current.slickPrev()
        setCantNext(prev => prev + 1)
    }

    return (
        <div>
            <div className={styles.banner}>
                <Slider ref={slider} {...settings}>
                    {categories.map(product => <div key={product.id} className={styles.grid}>
                        <ProductCard id={product.product_id}/></div>)}
                </Slider>
                {width <= 375 ? null :
                    <>
                        <div style={{position: 'absolute', top: '45%', left: '-38px'}}>
                            <div style={{}} className="button" onClick={prev}>
                                <img src={left}/>
                            </div>
                        </div>
                        <div style={{position: 'absolute', top: '45%', right: '-43px'}}>
                            <div className="button" onClick={next}>
                                <img src={right}/>
                            </div>
                        </div>
                    </>
                }
            </div>
        </div>
    );
}

/*import React, {Component} from "react";
import Slider from "react-slick";
import left from "../assets/images/bannerButtonLeft.svg";
import right from "../assets/images/bannerButtonRight.svg";
import styles from "./SimilarProductsCarousel.module.css"

export default class MultipleItems extends Component {

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }
    next() {
        this.slider.slickNext();
    }
    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3
        };
        return (
            <div>
                <Slider {...settings}>

                </Slider>
                <div style={{position: 'absolute', top: '45%', left: '-45px'}}>
                    <div style={{}} className="button" onClick={this.previous}>
                        <img src={left}/>
                    </div>
                </div>
                <div style={{position: 'absolute', top: '45%', right: '-40px'}}>
                    <div className="button" onClick={this.next}>
                        <img src={right}/>
                    </div>
                </div>
            </div>
        );
    }
}*/
