import React, {useContext, useEffect, useState} from "react";
import {Redirect, useHistory, useParams} from "react-router-dom";
import SText from "../../components/SText";
import RoundedButton from "../../components/RoundedButton";
import Colors from "../../utils/Colors";
import {Preloader} from "../../App";
import {getOrderToPay2Info} from "../../api";
import styles from './styles.module.css'
import TouchableOpacity from "../../components/TouchableOpacity";
import cart from '../../assets/images/cartWithProducts.svg'
import ScreenSuccess from "./components/ScreenSuccess";
import ScreenFail from "./components/ScreenFail";
import {launchPayment} from "../../utils/payment";
import DataContext from "../../data/DataContext";

const Pay2 = ({}) => {
    const {orderId} = useParams()
    const {push} = useHistory()
    const [result, setResult] = useState('')
    const {user} = useContext(DataContext)

    const [orderInfo, setOrderInfo] = useState(null)
    //const orderInfo = {amount: 0}



    async function getOrderInfo () {
        try {
            const response = await getOrderToPay2Info(orderId)
            if (response) {
               setOrderInfo(response)
            } else {
                push('/')
            }
        } catch (e) {
            push('/')
        }
    }

    useEffect(()=>{
        getOrderInfo()
    }, [orderId])

    if (!orderId) return <Redirect to={'/'}/>


    function onPay () {
        if (orderInfo.payUrl.length) {
            window.location.href = orderInfo.payUrl;
        } else {
            launchPayment({
                userId: orderInfo?.user_id,
                amount: parseFloat(orderInfo?.amount.toFixed(2)),
                orderId: orderId,
                preventRefresh: true,
                onSuccess: ()=>{
                    setResult('success')
                    user?.get()
                },
                onFail: () => {
                    setResult('fail')
                    user?.get()
                }
            })
        }
    }

    if (!orderInfo) return <Preloader/>

    if (result === 'success')
        return <ScreenSuccess orderId={orderId}/>

    if (result === 'fail')
        return <ScreenFail orderId={orderId} onTryAgain={()=>setResult('')}/>

    return <div className={styles.container}>
        <div className={styles.constraints}>
            <div className={styles.info}>
                <SText fontSize={34} fontWeight={900}>{`ОПЛАТА ЗАКАЗА НОМЕР\u00A0${orderId}`}</SText>
                <div style={{flex: 1, display: "flex", alignItems: 'center', justifyContent: 'center'}}>
                    <img src={cart}/>
                </div>
            </div>
            <RoundedButton activeColor={Colors.darkBlue}
                           onPress={onPay}
                           containerStyle={{
                               justifyContent: 'space-between',
                               padding: '0 20px',
                               alignSelf: 'center',
                               maxWidth: 400
                           }}>
                <SText color={'#fff'} fontWeight={550} fontSize={18}>
                    {'Оплатить'}
                </SText>
                <div>
                <SText color={'#fff'} fontWeight={550} fontSize={18}>
                    <strong>{orderInfo?.amount.toFixed(2)}</strong>
                </SText>
                    <SText fontSize={14} fontWeight={550} color={'#fff'} style={{fontWeight: 550}}>{'\u00A0₽'}</SText>
                </div>
            </RoundedButton>
            <TouchableOpacity style={{alignSelf: 'center', marginTop: 32}} onClick={() => push('/')}>
                <SText color={'#bdbdbd'} fontWeight={700} fontSize={14}
                       style={{textDecoration: 'underline', alignSelf: 'center'}}>
                    {'Оплатить позже'}
                </SText>
            </TouchableOpacity></div>
    </div>
}

export default Pay2
