import React from "react";
import styles from '../styles.module.css'
import check from '../../../assets/images/tick.svg'
import Icon from "../../../components/Icon";
import SText from "../../../components/SText";
import RoundedButton from "../../../components/RoundedButton";
import Colors from "../../../utils/Colors";

const ScreenSuccess = ({orderId=56}) => {
    return <div className={styles.container}>
        <div className={styles.constraints}>
            <SText fontSize={28} fontWeight={900} gap>{`ЗАКАЗ НОМЕР\u00A0${orderId} УСПЕШНО\u00A0ОПЛАЧЕН`}</SText>
            <div className={styles.checkContainer}>
                <div className={styles.checkOuter}>
                    <Icon iconSrc={check} size={160}/>
                </div>
            </div>
            <RoundedButton label={'На главную'} activeColor={Colors.mainGreen}/>
        </div>
    </div>
}

export default ScreenSuccess
