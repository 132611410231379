import React, {useEffect, useState} from "react";
import styles from './NewModal.module.css'
import cn from "classnames";
import {useEscape, useWindowDimensions} from "../utils/hooks";
import Div100vh, {use100vh} from "react-div-100vh";
import {bodyLocker} from "../utils/other";

const NewModal = ({
                      isVisible = false,
                      onClose = () => {
                      },
                      left = false,
                      minWidth = 0,
                      minHeight = 0,
                      fullScreen = true,
                      borderRadius,
                      margin,
                      ...otherProps
                  }) => {

    const [isShown, setShown] = useState(isVisible)
    const [mounted, setMounted] = useState(false)

    const overLayRef = useEscape(styles.overlay, onClose)
    const {windowDimensions: {width}} = useWindowDimensions()

    const height = use100vh()

    useEffect(() => {
        mounted ? bodyLocker.enable() : bodyLocker.disable()
    }, [mounted])

    useEffect(() => {
        if (isVisible) {
            setShown(true)
            setTimeout(() => {
                setMounted(true)
            }, 100)
        } else {
            setTimeout(() => {
                setShown(false)
                setMounted(false)
            }, 350)
        }
    }, [isVisible])

    if (!isShown) return null

    const setToFullScreen = width < Math.max(768, minWidth) && fullScreen


    return <Div100vh className={cn(styles.overlay, (!isVisible || !mounted) && styles.overlayInvisible)}
                     style={{justifyContent: left ? 'flex-end' : 'center'}}
                     ref={overLayRef}>
        <div style={{
            borderRadius: setToFullScreen ? 0 : (borderRadius || 20),
            width: setToFullScreen ? '100vw' : 'fit-content',
            minHeight: setToFullScreen ? 0 : minHeight,
            minWidth: setToFullScreen ? 0 : minWidth,
            height: setToFullScreen ? '100%' : 'fit-content',
            margin: margin ? margin : 0,
        }} className={cn(styles.container, (!isVisible || !mounted) && styles.containerInvisible)}>
            {otherProps.children}
        </div>
    </Div100vh>

}

export default NewModal
