import React, {useContext, useEffect, useState} from "react";
import SText from "../../../components/SText";
import Colors from "../../../utils/Colors";
import styles from '../../../components/Header.module.css'
import cn from "classnames";
import DataContext from "../../../data/DataContext";
import unionMobile from '../../../assets/images/unionMobile.svg'
import {text} from "../../../utils/mixins";
import {useWindowDimensions} from "../../../utils/hooks";
import {NavLink} from "react-router-dom";
import Search, {SearchPopupDesktop} from "../../../components/Search";
import {removeYear} from "../../../utils/other";
import moment from "moment";
import {inputFormat} from "../../../config";
import ModalContext from "../../../data/ModalContext";
import {RegionContext} from "../../../App";

export default () => {

    const {userLocation, catalogue, cart, user, getIsAuth, deliverySlots} = useContext(DataContext)
    const {region} = useContext(RegionContext)
    const [askForRegion, setAskForRegion] = useState(!userLocation.data)

    const regionName = region === 77 ? 'Москва' : 'Санкт-Петербург'

    const {open: _open} = useContext(ModalContext)

    const {start} = deliverySlots.data?.slots[0] || {}

    const nearestDelivery = start ? removeYear(moment(start, inputFormat).format('LLL')) : false

    const {windowDimensions: {width}} = useWindowDimensions()

    useEffect(() => {
        setTimeout(() => {
            setAskForRegion(false)
        }, 5000)
    }, [])

    if (width >= 992) return null
    return <>
        <NavLink onClick={() => {
            window.scrollTo(0, 0);
        }} to={"/delivery-and-payment"}><div className={styles.mobileDelivery}>
            {nearestDelivery && <div className={styles.delivery}>
                <span>{'Ближайшая доставка - '}</span>
                <div className={styles.date}><span>{nearestDelivery}</span></div>
            </div>}
{/*            <SText color={Colors.white} fontWeight={400} fontSize={14}>{'Ближайшая доставка -'}</SText><SText
            fontWeight={700} fontSize={14} color={Colors.white}
            style={{borderBottom: 'solid 1px #fff'}}>{' завтра 03 сентября'}</SText>*/}
        </div></NavLink>

        <div className={styles.mobileDiscount}>
            <SText fontSize={12} fontWeight={900} color={'#fff'}>{'СКИДКА 500 РУБ '}</SText>
            <SText fontSize={12} fontWeight={550} color={'#fff'}>{'ПРИ ЗАКАЗЕ НА '}</SText>
            <SText fontSize={12} fontWeight={900} color={'#fff'}>{'7000 РУБ '}</SText>
            <SText fontSize={12} fontWeight={550} color={'#fff'}>{'И'}</SText><br></br>
            <SText fontSize={12} fontWeight={900} color={'#fff'}>{'1000 РУБ '}</SText>
            <SText fontSize={12} fontWeight={550} color={'#fff'}>{'ПРИ ЗАКАЗЕ НА '}</SText>
            <SText fontSize={12} fontWeight={900} color={'#fff'}>{'10 000 РУБ'}</SText><br/>
            <SText fontSize={12} fontWeight={550} color={'#fff'}>{'ПО ПРОМОКОДАМ '}</SText>
            <SText fontSize={12} fontWeight={900} color={'#fff'}>{'SM500 '}</SText>
            <SText fontSize={12} fontWeight={550} color={'#fff'}>{'И '}</SText>
            <SText fontSize={12} fontWeight={900} color={'#fff'}>{'SM1000'}</SText>
        </div>

        <div style={{marginBottom: '17px'}}>
            <div onClick={()=>_open('region')} className={styles.mobileCity}>
                <div><img src={unionMobile} className={cn(styles.bubbleImg, !askForRegion && styles.fadeOut)}/>
                </div>
                {/*<SText style={{marginTop: '-10px', marginLeft: '-15px'}} fontSize={16} fontWeight={400}
                       color={'#909090'}>{'Ваш город: ' + 'Москва'}</SText>*/}
                <div className={styles.city} onClick={()=>_open('region')}>
                    <SText fontSize={18} fontWeight={400} color={'#BDBDBD'}>{'Ваш город: '}</SText> <SText style={{marginRight: '14px', borderBottom: '1px solid #bdbdbd'}} fontSize={18} fontWeight={400} color={'#BDBDBD'}>{regionName}</SText>
                </div>
            </div>
        </div>

        <div style={{display: 'flex', justifyContent: 'center', padding: '0 20px', position: 'relative'}}>
            <Search/>
            <SearchPopupDesktop/>
        </div>
    </>
}
