import React, {useEffect, useRef, useState} from "react";
import RoundedButton from "../../../components/RoundedButton";
import Colors from "../../../utils/Colors";
import styles from "../AuthModal.module.css"
import AgreementsBlock from "../../../components/AgreementsBlock";
import {text} from "../../../utils/mixins";
import PhoneInput from "./PhoneInput";
import {useTextMask, useWindowDimensions} from "../../../utils/hooks";
import SText from "../../../components/SText";

const SignIn = ({
                    onSubmit = () => {
                    }, initialPhone = '', error = '', onDismissError = () => {
    }
                }) => {
    const [phone, setPhone] = useState(initialPhone);
    const [agreed, setAgreed] = useState(true)

    async function handleSubmit() {
        await onSubmit(phone, agreed)
        window.mindbox("async", {
            operation: "Online.Authentication.JS",
            data: {
                customer: {
                    email: "",
                    mobilePhone: phone
                }
            }
        });
    }

    const {windowDimensions: {width}} = useWindowDimensions()
    useEffect(() => {
        if (phone.length < 18)
            onDismissError()
    }, [phone.length])


    return <div className={styles.container}>
        <div className={styles.topLabel}><SText fontSize={20} fontWeight={900}
                                                lineHeight={24}>{"ВВЕДИТЕ НОМЕР ТЕЛЕФОНА"}</SText></div>
        <PhoneInput onEnter={handleSubmit} value={phone} onValueChange={setPhone}/>
        <span className={styles.bottomLabel}>На указанный номер мы отправим вам сообщение с 4-х значным кодом</span>
        <RoundedButton label={"Отправить СМС"}
                       activeColor={Colors.mainGreen}
                       dimmedColor={Colors.mainGreenDimmed}
                       disabled={(phone?.length < 18)}
                       onPress={handleSubmit}
                       containerStyle={{maxWidth: '353px', alignSelf: 'center', marginBottom: width >= 768 ? 55 : 79}}
        />
        <SText className={styles.error} color={Colors.red}>{error}</SText>
        <AgreementsBlock isAgreed={agreed} setAgreed={setAgreed}/>
    </div>

}

export default SignIn