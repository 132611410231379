import React, {useContext, useEffect, useState} from 'react';
import styles from './Anketa.module.css'
import cn from "classnames";
import fullTick from '../../assets/images/anketa/tickFull.svg'
import male from '../../assets/images/anketa/anketa_male.png'
import female from '../../assets/images/anketa/anketa_female.png'
import checkbox from '../../assets/images/anketa/checkbox.svg'
import checkboxFull from '../../assets/images/anketa/checkboxFull.svg'
import pet1 from '../../assets/images/anketa/anketa_animal_1.png'
import pet2 from '../../assets/images/anketa/anketa_animal_2.png'
import pet3 from '../../assets/images/anketa/anketa_animal_3.png'
import pet4 from '../../assets/images/anketa/anketa_animal_4.png'
import pet5 from '../../assets/images/anketa/anketa_animal_5.png'
import children1 from '../../assets/images/anketa/firstChild.svg'
import children2 from '../../assets/images/anketa/secondChild.svg'
import children3 from '../../assets/images/anketa/thirdChild.svg'
import home from '../../assets/images/anketa/home.svg'
import apartment from '../../assets/images/anketa/apartment.svg'
import office from '../../assets/images/anketa/office.svg'
import blondFemale from '../../assets/images/anketa/blondFemale.svg'
import thunderphone from '../../assets/images/anketa/thunderphone.svg'
import search from '../../assets/images/anketa/search.svg'
import bannerPhone from '../../assets/images/anketa/bannerPhone.svg'
import blondFrien from '../../assets/images/anketa/blondFriend.svg'
import friendShake from '../../assets/images/anketa/friendShake.svg'
import car from '../../assets/images/anketa/car.svg'
import road from '../../assets/images/anketa/road.svg'
import bike from '../../assets/images/anketa/bike.svg'
import tv from '../../assets/images/anketa/tv.svg'
import radio from '../../assets/images/anketa/radio.svg'
import eye from '../../assets/images/anketa/eye.svg'
import disabled from '../../assets/images/anketa/disabled.svg'
import plusCircle from '../../assets/images/anketa/PlusCircle.svg'
/*import goBack from '../../assets/images/cart/goBack.svg'*/
import {useWindowDimensions} from "../../utils/hooks";
import DataContext from "../../data/DataContext";
import SText from "../SText";
import axios from "axios";
import {BASE_URL} from "../../config";
import Header from "../Header";
import PhoneInput from "../../screens/AuthModal/components/PhoneInput";
import {searchAll} from "../../api";
import useSearch from "../../data/SearchContext";
import {useLocation} from "react-router-dom";

const stores = [
    {title: 'Перекресток', fieldName: 'perekrestok', value: [], disabledIndex: false},
    {title: 'ВкусВилл', fieldName: 'vkusvill', value: [], disabledIndex: false},
    {title: 'Азбука Вкуса', fieldName: 'azbukavkusa', value: [], disabledIndex: false},
    {title: 'Пятёрочка', fieldName: 'pyaterochka', value: [], disabledIndex: false},
    {title: 'Магнит', fieldName: 'marnit', value: [], disabledIndex: false},
    {title: 'Утконос', fieldName: 'utkonos', value: [], disabledIndex: 1},
    {title: 'OZON Express', fieldName: 'ozonexpress', value: [], disabledIndex: 1},
    {title: 'Самокат', fieldName: 'samokat', value: [], disabledIndex: 1},
    {title: 'Ашан', fieldName: 'ashan', value: [], disabledIndex: false},
    {title: 'Яндекс Лавка', fieldName: 'yandexlavka', value: [], disabledIndex: 1},
    {title: 'Сбермаркет', fieldName: 'sbermarket', value: [], disabledIndex: 1},
    {title: 'Лента', fieldName: 'lenta', value: [], disabledIndex: false},
    {title: 'Гипер Глобус', fieldName: 'giperglobus', value: [], disabledIndex: false},
    {title: 'Euro Spar', fieldName: 'eurospar', value: [], disabledIndex: false},
    {title: 'Только Season Market', fieldName: 'seasonmarket', value: [], disabledIndex: 1},
    {title: 'другое', fieldName: 'else', value: [], disabledIndex: false},
]

const petsArray = [
    {value: '1', img: pet1, name: 'птичка'},
    {value: '2', img: pet2, name: 'кошка'},
    {value: '3', img: pet3, name: 'собачка'},
    {value: '4', img: pet4, name: 'рыбки'},
    {value: '5', img: pet5, name: 'другое'},
    {value: '6', img: null, name: 'нет'},
]

const childrenArray = [
    {value: '0', img: null, name: '0'},
    {value: '1', img: [children1], name: '1'},
    {value: '2', img: [children1, children2], name: '2'},
    {value: '3', img: [children1, children2, children3], name: '3'},
    {value: '4', img: [children1, children2, children3, children1], name: '4+'},
]

const pointArray = [
    {value: '1', img: home, name: 'Частный дом'},
    {value: '2', img: apartment, name: 'Квартира'},
    {value: '3', img: office, name: 'Офис'},
]

const howKnowsArray = [
    {value: '1', img: [blondFemale], name: 'Блогеры Инстаграм'},
    {value: '2', img: [female], name: 'Прочие блогеры'},
    {value: '3', img: [thunderphone], name: 'Наш Инстаграм'},
    {value: '4', img: [search], name: 'В яндекс, гугл поисковике'},
    {value: '5', img: [bannerPhone], name: 'Баннерная в Интернете'},
    {value: '6', img: [blondFrien, friendShake], name: 'Посоветовал друг'},
    {value: '7', img: [car, road, bike], name: 'Увидели на улице'},
    {value: '8', img: [tv, radio], name: 'ТВ, радио'},
    {value: '9', img: [eye], name: 'Другое'},
]

const monthArray = [
    {value: 1, name: 'Январь'},
    {value: 2, name: 'Февраль'},
    {value: 3, name: 'Март'},
    {value: 4, name: 'Апрель'},
    {value: 5, name: 'Май'},
    {value: 6, name: 'Июнь'},
    {value: 7, name: 'Июль'},
    {value: 8, name: 'Август'},
    {value: 9, name: 'Сентябрь'},
    {value: 10, name: 'Октябрь'},
    {value: 11, name: 'Ноябрь'},
    {value: 12, name: 'Декабрь'},
]

const foodArray = [
    {value: 1, name: 'Вегетарианец'},
    {value: 2, name: 'Веган'},
    {value: 3, name: 'Кошер'},
    {value: 4, name: 'Халяль'},
    {value: 5, name: 'Без глютена'},
    {value: 6, name: 'Без сахара'},
    {value: 7, name: 'Без лактозы'},
    {value: 8, name: 'Нет предпочтений'},
]

const weekendArray = [
    {value: 1, name: 'Парк'},
    {value: 2, name: 'Кино'},
    {value: 3, name: 'Спортзал'},
    {value: 4, name: 'Шопинг'},
    {value: 5, name: 'Лежать на диване'},
    {value: 6, name: 'Всегда выходной'},
    {value: 7, name: 'Другое'},
]

const socialsArray = [
    {value: 1, name: 'Instagram'},
    {value: 2, name: 'Youtube'},
    {value: 3, name: 'VK'},
    {value: 4, name: 'Tik-Tok'},
    {value: 5, name: 'Яндекс Дзен'},
]

const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validateEmail = (email) => {
    return emailRegex.test(email);
}

export const api = axios.create({baseURL: BASE_URL})

const Anketa = () => {
    const {user} = useContext(DataContext)
    const [name, setName] = useState(user?.data?.full_name || '')
    const [phone, setPhone] = useState(user?.data?.phone || '')
    const [mail, setMail] = useState(user?.data?.email || '')
    const [birthday, setBirthday] = useState('')
    const [validBirthday, setValidBirthday] = useState('')
    const [sex, setSex] = useState('')
    const [region, setRegion] = useState('0')
    const [regionElse, setRegionElse] = useState('')
    const [pet, setPet] = useState([])
    const [children, setChildren] = useState('')
    const [point, setPoint] = useState('')
    const [how, setHow] = useState([])
    const [otherStores, setOtherStores] = useState(stores)
    const [otherStoresElse, setOtherStoresElse] = useState('')
    const [products, setProducts] = useState({product1: '', product2: '', product3: ''})
    const [months, setMonths] = useState([])
    const [countries, setCountries] = useState({country1: '', country2: '', country3: ''})
    const [food, setFood] = useState([])
    const [weekends, setWeekends] = useState([])
    const [organic, setOrganic] = useState('')
    const [socials, setSocials] = useState([])
    const [inst, setInst] = useState('')
    const [popularStores, setPopularStores] = useState(stores.map((item) => ({
        ...item,
        value: false,
        disabledIndex: false,
    })))
    const [points, setPoints] = useState(0)
    const [questions, setQuestions] = useState(0)
    const [success, setSuccess] = useState(false)
    const [agree, setAgree] = useState(false)
    const {windowDimensions: {width}} = useWindowDimensions()
    const search = useLocation().search
    const searchParams = new URLSearchParams(search)

    const countPoints = () => {
        let points = 0
        let questions = 0
        if (name.trim().length) {
            points += 20
            questions += 1
        }
        if (phone.length === 18) {
            questions += 1
            points += 100
        }
        if (validateEmail(mail)) {
            questions += 1
            points += 100
        }
        if (validBirthday.length) {
            questions += 1
            points += 100
        }
        if (sex.length) {
            questions += 1
            points += 20
        }
        if (region > 0) {
            questions += 1
            points += 40
        }
        if (pet.length) {
            questions += 1
            points += 20
        }
        if (children.length) {
            questions += 1
            points += 20
        }
        if (point.length) {
            questions += 1
            points += 20
        }
        if (how.length) {
            questions += 1
            points += 60
        }
        if (otherStores.filter(item => item.value.length !== 0).length !== 0) {
            questions += 1
            points += 200
        }
        if (Object.values(products).filter(f => f.trim() !== '').length > 0) {
            questions += 1
            points += 100
        }
        if (months.length) {
            questions += 1
            points += 60
        }
        if (Object.values(countries).filter(f => f.trim() !== '').length > 0) {
            questions += 1
            points += 20
        }
        if (food.length) {
            questions += 1
            points += 50
        }
        if (weekends.length) {
            questions += 1
            points += 20
        }
        if (socials.length) {
            questions += 1
            points += 50
        }
        if (inst.length) {
            questions += 1
            points += 100
        }
        if (popularStores.filter(item => item.value !== false).length > 0) {
            questions += 1
            points += 100
        }

        setPoints(points)
        setQuestions(questions)
    }

    const submit = async () => {
        let TOKEN = "UNDEFINED";
        if (user.data) {
            TOKEN = user?.data?.apikey;
        }
        if (phone.length === 18) {
            let SEND_DATA = {
                phone,
                name,
                email: mail,
                whatsapp: '',
                product_1: products.product1,
                product_2: products.product2,
                product_3: products.product3,
                region,
                region_else: regionElse,
                birthday: validBirthday,
                sex,
                organic,
                point,
                family_status: '',
                children,
                animals: '',
                animal_types: pet,
                where: '',
                how,
                country_1: countries.country1,
                country_2: countries.country2,
                country_3: countries.country3,
                days: '',
                food_t: food,
                other_stores: otherStores,
                other_stores_else: otherStoresElse,
                popular_stores: popularStores,
                popular_stores_else: '',
                daysInYear: [],
                months,
                food_c: [],
                group: [],
                weekends,
                rest: [],
                service: [],
                big_group: '',
                socials,
                instagram: inst,
                earned: points,
                questions,
                apikey: TOKEN,
            };

            let data = await api.post('question/create ', SEND_DATA)
            if (data !== false) {

                if (data.data.result === 'ok')
                    setSuccess(true)
            } else {
            }
        }
    }

    useEffect(() => {
        document.body.style.backgroundColor = '#F7F7F7'
        return () => {
            document.body.style.backgroundColor = '#fff'
        }
    }, [])

    useEffect(() => {
        countPoints()
    }, [name, phone, mail, validBirthday, sex, region, pet,
        children, point, how, otherStores, products, months,
        countries, food, weekends, organic, socials, inst, popularStores])

    return (
        <div>
            {!searchParams.has('hideNavs') && <div className={styles.header}>
                <Header/>
            </div>}
            <div className={styles.wrapper}>

                <div className={styles.title}>{'Анкета-опросник'}</div>
                <div className={styles.greenTitle}>{'Заполните анкету и получите 1200 бонусных баллов!'}</div>

                {(!user?.data?.isPolled || !user.data) ?
                    success ?
                        <div><SText size={18} weight={700} lineHeight={18}>{'Анкета успешно заполнена!'}</SText></div> :
                        <>
                            <div
                                /*style={{top: true ? (width > 1023 ? 100 : 56) : (width > 1023 ? 100 : 0)}}*/
                                className={styles.earnedWrapper}>
                                <SText size={18} weight={500} color={'#818181'}>{`${questions} из 20`}</SText>
                                <div style={{display: 'flex', alignItems: "center", gap: 10}}>
                                    <SText size={12} weight={500} lineHeight={12}>{'Набрано баллов '}</SText>
                                    <div className={styles.pointsEarned}><SText size={18} weight={500}
                                                                                lineHeight={18}>{points}</SText>
                                    </div>
                                </div>
                            </div>

                            <Name name={name} setName={setName}/>

                            <Phone phone={phone} setPhone={setPhone}/>

                            <Mail mail={mail} setMail={setMail}/>

                            <Birthday birthday={birthday} setBirthday={setBirthday}
                                      setValidBirthday={setValidBirthday}/>

                            <Sex sex={sex} setSex={setSex}/>

                            <Region region={region} regionElse={regionElse} setRegion={setRegion}
                                    setRegionElse={setRegionElse}/>

                            <Pets pet={pet} setPet={setPet}/>

                            <Children children={children} setChildren={setChildren}/>

                            <Point point={point} setPoint={setPoint}/>

                            <HowKnows how={how} setHow={setHow}/>

                            <OtherStores otherStores={otherStores} setOtherStores={setOtherStores}
                                         otherStoresElse={otherStoresElse}
                                         setOtherStoresElse={setOtherStoresElse}/>

                            <Products products={products} setProducts={setProducts}/>

                            <Month month={months} setMonth={setMonths}/>

                            <Countries countries={countries} setCountries={setCountries}/>

                            <Food food={food} setFood={setFood}/>

                            <Weekends weekends={weekends} setWeekends={setWeekends}/>


                            <Socials socials={socials} setSocials={setSocials}/>

                            <Inst inst={inst} setInst={setInst}/>

                            <PopularStores otherStores={otherStores} popularStores={popularStores}
                                           setPopularStores={setPopularStores}/>

                            <div onClick={() => setAgree(prev => !prev)} className={styles.agree}>
                                <img src={agree ? checkboxFull : checkbox}/>
                                <SText size={14} weight={500}
                                       lineHeight={14}>{'Сограсен на обработку персональных данных'}</SText>
                            </div>

                            <div onClick={submit} className={styles.submitBtn}><SText size={16} lineHeight={16}
                                                                                      weight={500}
                                                                                      color={'#fff'}>{'Отправить ответы'}</SText>
                            </div>
                        </> :
                    <div><SText size={18} weight={700} lineHeight={18}>{'Вы уже заполняли анкету ранее!'}</SText></div>}
            </div>
        </div>
    );
};

const Name = ({name, setName}) => {

    return <div className={styles.qWrapper}>
        <div className={styles.qTitle}>1. <span>{' Ваше имя'}</span></div>
        <div className={styles.qFlex}>
            <div className={styles.nameInput}>
                <input placeholder={'Как вас зовут?'} value={name} onChange={e => setName(e.target.value)} type="text"/>
            </div>
            <div className={styles.qPoints}>
                <div className={name.length <= 1 && styles.emptyCircle}>{name.length > 1 &&
                    <img src={fullTick}/>}</div>
                <div className={name.length <= 1 ? styles.amountGray : styles.amountGreen}>{'+20'}</div>
            </div>
        </div>
    </div>
}

const Phone = ({phone, setPhone}) => {

    return <div className={styles.qWrapper}>
        <div className={styles.qTitle}>2. <span>{' Ваш номер телефона'}</span></div>
        <div className={styles.qFlex}>
            <div className={styles.nameInput}>
                <PhoneInput onValueChange={setPhone} value={phone}/>
            </div>
            <div className={styles.qPoints}>
                <div className={phone.length < 18 && styles.emptyCircle}>{phone.length === 18 &&
                    <img src={fullTick}/>}</div>
                <div className={phone.length < 18 ? styles.amountGray : styles.amountGreen}>{'+100'}</div>
            </div>
        </div>
    </div>
}

const Mail = ({mail, setMail}) => {

    return <div className={styles.qWrapper}>
        <div className={styles.qTitle}>3. <span>{' Адрес вашей электронной почты'}</span></div>
        <div className={styles.qFlex}>
            <div className={styles.nameInput}>
                <input placeholder={'Электронная почта'} value={mail} onChange={e => setMail(e.target.value)}
                       type="text"/>
            </div>
            <div className={styles.qPoints}>
                <div className={!validateEmail(mail) && styles.emptyCircle}>{validateEmail(mail) &&
                    <img src={fullTick}/>}</div>
                <div className={!validateEmail(mail) ? styles.amountGray : styles.amountGreen}>{'+100'}</div>
            </div>
        </div>
    </div>
}

const Birthday = ({birthday, setBirthday, setValidBirthday}) => {
    const dayValue = (day) => {
        const d = Number(day.target.value)
        if ((d < 1 || d > 31 || d % 1 !== 0) && day.target.value !== '')
            return

        setBirthday({...birthday, day: day.target.value})
    }

    const monthValue = (month) => {
        const m = Number(month.target.value)
        if ((m < 1 || m > 12 || m % 1 !== 0) && month.target.value !== '')
            return

        setBirthday({...birthday, month: month.target.value})
    }

    const yearValue = (year) => {
        const y = Number(year.target.value)
        if ((y < 1 || y > 2021 || y % 1 !== 0) && year.target.value !== '')
            return

        setBirthday({...birthday, year: year.target.value})
    }

    const validate = () => {
        if (!!birthday.day && !!birthday.month && !!birthday.year && birthday.year.length === 4) {
            const date = `${birthday.day}-${birthday.month}-${birthday.year}`
            setValidBirthday(date)
            return true
        }
        setValidBirthday('')
        return false
    }

    return <div className={styles.qWrapper}>
        <div className={styles.qTitle}>4. <span>{' День рождения'}</span></div>
        <div className={styles.qFlex}>
            <div className={cn(styles.nameInput, styles.birthdayInput)}>
                <input style={{marginRight: '9px', padding: '0 14px', width: '75px', height: '55px'}}
                       placeholder={'ДД'} value={birthday?.day || ''} onChange={e => dayValue(e)} type="number"/>
                <input style={{marginRight: '9px', padding: '0 14px', width: '75px', height: '55px'}}
                       placeholder={'MM'} value={birthday?.month || ''} onChange={e => monthValue(e)} type="number"/>
                <input style={{padding: '0 14px', width: '100%', maxWidth: '112px', height: '55px'}}
                       placeholder={'ГГГГ'} value={birthday?.year || ''} onChange={e => yearValue(e)} type="number"/>
            </div>
            <div className={styles.qPoints}>
                <div className={!validate() && styles.emptyCircle}>{validate() &&
                    <img src={fullTick}/>}</div>
                <div className={!validate() ? styles.amountGray : styles.amountGreen}>{'+100'}</div>
            </div>
        </div>
    </div>
}

const Sex = ({sex, setSex}) => {

    return <div className={styles.qWrapper}>
        <div className={styles.qTitle}>5. <span>{' Ваш пол'}</span></div>
        <div style={{justifyContent: 'space-between'}} className={styles.qFlex}>
            <div className={styles.sexInput}>
                <div onClick={() => setSex('MALE')}>
                    <div className={sex === 'MALE' ? styles.fullRadioCircle : styles.emptyRadioCircle}/>
                    <img src={male}/>
                </div>
                <div onClick={() => setSex('FEMALE')}>
                    <div className={sex === 'FEMALE' ? styles.fullRadioCircle : styles.emptyRadioCircle}/>
                    <img src={female}/>
                </div>
            </div>
            <div className={styles.qPoints}>
                <div className={sex.length === 0 && styles.emptyCircle}>{sex.length !== 0 &&
                    <img src={fullTick}/>}</div>
                <div className={sex.length === 0 ? styles.amountGray : styles.amountGreen}>{'+20'}</div>
            </div>
        </div>
    </div>
}

const Region = ({region, regionElse, setRegion, setRegionElse}) => {

    return <div className={styles.qWrapper}>
        <div className={styles.qTitle}>6. <span>{' Ваш регион'}</span></div>
        <div className={styles.qFlex}>
            <div style={{height: 'initial'}} className={styles.nameInput}>
                <select style={{marginBottom: 15}} onChange={(e) => setRegion(e.target.value)}>
                    <option value="0">Выберите свой регион</option>
                    <option value="77">Москва и Московская область</option>
                    <option value="78">Санкт-Петербург и Ленинградская область</option>
{/*                    <option value="16">Казань и Республика Татарстан</option>
                    <option value="52">Нижний Новгород и Нижегородская область</option>
                    <option value="61">Ростов-на-Дону и Ростовская область</option>
                    <option value="36">Воронеж и Воронежская область</option>
                    <option value="34">Волгоград и Волгоградская область</option>
                    <option value="1">Другой</option>*/}
                </select>
                {region === '1' && <input placeholder={'Название населённого пункта'} value={regionElse}
                                          onChange={e => setRegionElse(e.target.value)}
                                          type="select"/>}
            </div>
            <div className={styles.qPoints}>
                <div className={region === '0' && styles.emptyCircle}>{region > 0 &&
                    <img src={fullTick}/>}</div>
                <div className={region <= 0 ? styles.amountGray : styles.amountGreen}>{'+40'}</div>
            </div>
        </div>
    </div>
}

const Pets = ({pet, setPet}) => {

    return <div className={styles.qWrapper}>
        <div className={styles.qTitle}>7. <span>{' Домашние животные'}</span></div>
        <div className={styles.qFlex}>

            <div className={styles.pets}>
                {
                    petsArray.map(({value, img, name}) => <div onClick={() => {
                        if (pet.includes(value))
                            setPet(pet.filter((f) => f !== value))
                        else setPet([...pet, value])
                    }} className={styles.petRow}>
                        <img src={pet.includes(value) ? checkboxFull : checkbox}/>
                        <SText size={15} weight={500} lineHeight={15}>{name}</SText>
                        {img && <img src={img}/>}
                    </div>)
                }
            </div>
            <div className={styles.qPoints}>
                <div className={pet.length <= 0 && styles.emptyCircle}>{pet.length > 0 &&
                    <img src={fullTick}/>}</div>
                <div className={pet.length <= 0 ? styles.amountGray : styles.amountGreen}>{'+20'}</div>
            </div>
        </div>
    </div>
}

const Children = ({children, setChildren}) => {

    return <div className={styles.qWrapper}>
        <div className={styles.qTitle}>8. <span>{' Сколько детей в семье?'}</span></div>
        <div className={styles.qFlex}>
            <div className={styles.children}>
                {
                    childrenArray.map(({value, img, name}) => <div onClick={() => {
                        setChildren(value)
                    }} className={styles.childrenRow}>
                        <div className={children === value ? styles.fullRadioCircle : styles.emptyRadioCircle}/>
                        <SText size={15} weight={500} lineHeight={15}>{name}</SText>
                        {
                            img && img.map(img => <img src={img}/>)
                        }
                    </div>)
                }
            </div>
            <div className={styles.qPoints}>
                <div className={!children.length && styles.emptyCircle}>{children.length ?
                    <img src={fullTick}/> : null}</div>
                <div className={!children.length ? styles.amountGray : styles.amountGreen}>{'+20'}</div>
            </div>
        </div>
    </div>
}

const Point = ({point, setPoint}) => {

    return <div className={styles.qWrapper}>
        <div className={styles.qTitle}>9. <span>{' Куда чаще всего заказываете?'}</span></div>
        <div className={styles.qFlex}>
            <div className={styles.children}>
                {
                    pointArray.map(({value, img, name}) => <div onClick={() => {
                        setPoint(value)
                    }} className={styles.childrenRow}>
                        <div className={point === value ? styles.fullRadioCircle : styles.emptyRadioCircle}/>
                        <SText size={15} weight={500} lineHeight={15}>{name}</SText>
                        <img src={img}/>
                    </div>)
                }
            </div>
            <div className={styles.qPoints}>
                <div className={!point.length && styles.emptyCircle}>{point.length ?
                    <img src={fullTick}/> : null}</div>
                <div className={!point.length ? styles.amountGray : styles.amountGreen}>{'+20'}</div>
            </div>
        </div>
    </div>
}

const HowKnows = ({how, setHow}) => {

    return <div className={styles.qWrapper}>
        <div className={styles.qTitle}>10. <span>{' Как вы узнали о нас?'}</span></div>
        <div className={styles.qFlex}>

            <div className={styles.how}>
                {
                    howKnowsArray.map(({value, img, name}) => <div onClick={() => {
                        if (how.includes(value))
                            setHow(how.filter((f) => f !== value))
                        else setHow([...how, value])
                    }} className={styles.petRow}>
                        <img src={how.includes(value) ? checkboxFull : checkbox}/>
                        <SText size={15} weight={500} lineHeight={15}>{name}</SText>
                        {img.map(img => <img src={img}/>)}
                    </div>)
                }
            </div>
            <div className={styles.qPoints}>
                <div className={how.length <= 0 && styles.emptyCircle}>{how.length > 0 &&
                    <img src={fullTick}/>}</div>
                <div className={how.length <= 0 ? styles.amountGray : styles.amountGreen}>{'+60'}</div>
            </div>
        </div>
    </div>
}

const OtherStores = ({otherStores, setOtherStores, otherStoresElse, setOtherStoresElse}) => {
    const earned = otherStores.filter(item => item.value.length !== 0).length !== 0
    return <div style={{marginBottom: 30}} className={styles.qWrapper}>
        <div className={styles.qTitleSpecial}>
            <div>
                <div style={{display: 'flex', gap: 5, marginBottom: 15}}>
                    <SText size={14} weight={500} lineHeight={14} color={'#818181'}>{'11.'}</SText>
                    <SText size={14} weight={700} lineHeight={14}>{'Где вы еще покупаете продукты'} <br/>
                        {'(уточните, онлайн или офлайн)'}</SText>
                </div>
                <div style={{marginLeft: 23, marginBottom: 10}}>
                    <SText size={14} weight={700} lineHeight={14}>{'Зная ваши предпочтения, мы станем'} <br/>
                        {'лучше и удобнее для вас'}</SText>
                </div>
            </div>
            <div>
                <div className={styles.qPoints}>
                    <div className={!earned && styles.emptyCircle}>{earned &&
                        <img src={fullTick}/>}</div>
                    <div className={!earned ? styles.amountGray : styles.amountGreen}>{'+200'}</div>
                </div>
            </div>
        </div>

        <div style={{marginBottom: otherStores[15].value.length ? 10 : 0}} className={styles.qColumn}>
            <div className={styles.storesTitle}>
                <div style={{marginBottom: 5}}><SText size={20} weight={700} lineHeight={20}>{'покупаете'}</SText></div>
                <div><SText size={16} lineHeight={16} weight={700}>{'онлайн или офлайн'}</SText></div>
            </div>
            <div>
                {
                    otherStores.map(({title, fieldName, value, disabledIndex}) => <div
                        className={styles.storeRow}>
                        <SText size={16} lineHeight={16} weight={500}>{title}</SText>
                        <div className={styles.multiCheckboxes}>
                            <img onClick={() => {
                                setOtherStores(prev => prev.map(item => {
                                    if (item.fieldName !== fieldName) return item
                                    else return {
                                        ...item,
                                        value: item.value.includes('0') ? item.value.filter(f => f !== '0') : [...item.value, '0']
                                    }
                                }))
                            }} src={value.includes('0') ? checkboxFull : checkbox}/>
                            {disabledIndex ? <img style={{marginRight: 2}} src={disabled}/> :
                                <img onClick={() => {
                                    setOtherStores(prev => prev.map(item => {
                                        if (item.fieldName !== fieldName) return item
                                        else return {
                                            ...item,
                                            value: item.value.includes('1') ? item.value.filter(f => f !== '1') : [...item.value, '1']
                                        }
                                    }))
                                }} src={value.includes('1') ? checkboxFull : checkbox}/>}
                        </div>
                    </div>)
                }
            </div>
        </div>

        {
            otherStores[15].value.length ?
                <div className={styles.qFlex}>
                    <div className={styles.nameInput}>
                        <input placeholder={'Напишите название, если другое'} value={otherStoresElse}
                               onChange={e => setOtherStoresElse(e.target.value)} type="text"/>
                    </div>
                </div> : null
        }

    </div>
}

const Products = ({products, setProducts}) => {
    const earned = Object.values(products).filter(f => f.trim() !== '').length > 0

    return <div style={{marginBottom: 30}} className={styles.qWrapper}>
        <div style={{display: 'flex', gap: 3}} className={styles.qTitle}>12. <SText div size={14} lineHeight={14}
                                                                                    weight={700}>{' Выберите 3 любимых товара.'}
            <br/> {'(Со временем на эти 3 товара будет скидка)'}</SText></div>
        <div className={styles.qFlex}>
            <div style={{height: 'initial'}} className={styles.nameInput}>
                <ProductsInput products={products} product={'product1'} setProduct={setProducts}/>
                <ProductsInput products={products} product={'product2'} setProduct={setProducts}/>
                <ProductsInput products={products} product={'product3'} setProduct={setProducts}/>
            </div>
            <div className={styles.qPoints}>
                <div className={!earned && styles.emptyCircle}>{earned &&
                    <img src={fullTick}/>}</div>
                <div className={!earned ? styles.amountGray : styles.amountGreen}>{'+100'}</div>
            </div>
        </div>
    </div>
}

const ProductsInput = ({product, setProduct, products}) => {
    const [text, setText] = useState('')
    const {getProductByEcoId} = useContext(DataContext)
    const {onQueryChange, suggestions} = useSearch()
    const [searchResults, setSearchResults] = useState(false)
    const [focused, setFocused] = useState(false)
    const [data, setData] = useState(false)

    const loadProduct = (id) => {
        return getProductByEcoId(id)
    }

    const search = async (string) => {
        const data = await searchAll(string).then(r => {
            return r?.results?.ids
        })
        setData(data)
    }

    useEffect(async () => {
        if (data) {
            let result = data.map(id => {
                return loadProduct(id)
            }).filter(item => item !== null).slice(0, 5)
            setSearchResults(result)
        }
    }, [data])

    useEffect(async () => {
        await search(text)
        await onQueryChange(text)
    }, [text])

    return <div style={{marginBottom: 5, position: 'relative'}}>

        <input placeholder={'Выберите товар'}
               value={text}
               onChange={e => setText(e.target.value)}
               type="text"
               onBlur={() => setTimeout(() => setFocused(false), 300)}
               onFocus={() => setFocused(true)}
        />
        {(searchResults && focused && text !== '') &&
            <div className={styles.suggestionsWrapper}>
                {
                    searchResults && searchResults.map((item) => {
                        return <div onClick={() => {
                            setText(item.title)
                            setProduct({...products, [product]: item.id})
                            setTimeout(() => setFocused(false)
                                , 500)
                        }} className={styles.suggest}>{item.title}</div>
                    })
                }
            </div>
        }
    </div>

}

const Month = ({month, setMonth}) => {

    return <div className={styles.qWrapper}>
        <div style={{display: 'flex', gap: 3}} className={styles.qTitle}>13. <SText div size={14} lineHeight={14}
                                                                                    weight={700}>{' В какие месяцы вы уезжаете на'}
            <br/> {'отдых и отсутствуете в городе ?'}</SText></div>
        <div className={styles.qFlex}>
            <div className={styles.pets}>
                {
                    monthArray.map(({value, name}) => <div onClick={() => {
                        if (month.includes(value))
                            setMonth(month.filter((f) => f !== value))
                        else setMonth([...month, value])
                    }} className={styles.petRow}>
                        <img src={month.includes(value) ? checkboxFull : checkbox}/>
                        <SText size={15} weight={500} lineHeight={15}>{name}</SText>
                    </div>)
                }
            </div>
            <div className={styles.qPoints}>
                <div className={month.length <= 0 && styles.emptyCircle}>{month.length > 0 &&
                    <img src={fullTick}/>}</div>
                <div className={month.length <= 0 ? styles.amountGray : styles.amountGreen}>{'+60'}</div>
            </div>
        </div>
    </div>
}

const Countries = ({countries, setCountries}) => {
    const earned = Object.values(countries).filter(f => f.trim() !== '').length > 0
    const [visibleInputs, setVisibleInputs] = useState(1)

    return <div className={styles.qWrapper}>
        <div className={styles.qTitle}>14. <span>{' Какие страны вы обычно посещаете?'}</span></div>
        <div className={styles.qFlex}>
            <div style={{height: 'initial'}} className={styles.nameInput}>
                <input style={{marginBottom: 5}} placeholder={'Название страны'} value={countries.country1}
                       onChange={e => setCountries({...countries, country1: e.target.value})} type="text"/>
                {visibleInputs > 1 &&
                    <input style={{marginBottom: 5}} placeholder={'Название страны'} value={countries.country2}
                           onChange={e => setCountries({...countries, country2: e.target.value})} type="text"/>}
                {visibleInputs === 3 && <input placeholder={'Название страны'} value={countries.country3}
                                               onChange={e => setCountries({...countries, country3: e.target.value})}
                                               type="text"/>}
                {visibleInputs < 3 && <div onClick={() => setVisibleInputs(prev => prev + 1)} style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 6,
                    marginTop: 12,
                    cursor: 'pointer',
                    width: 'fit-content'
                }}><img src={plusCircle}/> <SText size={14} lineHeight={14} color={'#818181'}
                                                  weight={400}>{'добавить ещё страну'}</SText></div>}
            </div>
            <div className={styles.qPoints}>
                <div className={!earned && styles.emptyCircle}>{earned &&
                    <img src={fullTick}/>}</div>
                <div className={!earned ? styles.amountGray : styles.amountGreen}>{'+20'}</div>
            </div>
        </div>
    </div>
}

const Food = ({food, setFood}) => {

    return <div className={styles.qWrapper}>
        <div className={styles.qTitle}>15. <span>{' Предпочтения в питании'}</span></div>
        <div className={styles.qFlex}>
            <div className={styles.pets}>
                {
                    foodArray.map(({value, name}) => <div onClick={() => {
                        if (food.includes(value))
                            setFood(food.filter((f) => f !== value))
                        else setFood([...food, value])
                    }} className={styles.petRow}>
                        <img src={food.includes(value) ? checkboxFull : checkbox}/>
                        <SText size={15} weight={500} lineHeight={15}>{name}</SText>
                    </div>)
                }
            </div>
            <div className={styles.qPoints}>
                <div className={food.length <= 0 && styles.emptyCircle}>{food.length > 0 &&
                    <img src={fullTick}/>}</div>
                <div className={food.length <= 0 ? styles.amountGray : styles.amountGreen}>{'+50'}</div>
            </div>
        </div>
    </div>
}

const Weekends = ({weekends, setWeekends}) => {

    return <div className={styles.qWrapper}>
        <div className={styles.qTitle}>16. <span>{' Куда бы вы пошли в свой выходной'}</span></div>
        <div className={styles.qFlex}>
            <div className={styles.pets}>
                {
                    weekendArray.map(({value, name}) => <div onClick={() => {
                        if (weekends.includes(value))
                            setWeekends(weekends.filter((f) => f !== value))
                        else setWeekends([...weekends, value])
                    }} className={styles.petRow}>
                        <img src={weekends.includes(value) ? checkboxFull : checkbox}/>
                        <SText size={15} weight={500} lineHeight={15}>{name}</SText>
                    </div>)
                }
            </div>
            <div className={styles.qPoints}>
                <div className={weekends.length <= 0 && styles.emptyCircle}>{weekends.length > 0 &&
                    <img src={fullTick}/>}</div>
                <div className={weekends.length <= 0 ? styles.amountGray : styles.amountGreen}>{'+20'}</div>
            </div>
        </div>
    </div>
}

/*const Organic = ({organic, setOrganic}) => {

    return <div className={styles.qWrapper}>
        <div style={{display: 'flex', gap: 3}} className={styles.qTitle}>17. <SText div size={14} lineHeight={14}
                                                                                    weight={700}>{' Имеет ли для вас значение'}
            <br/> {'слово «органика»'}</SText>
            <img style={{padding: '0 0 0 22px'}} src={organicLogo}/></div>
        <div style={{justifyContent: 'space-between'}} className={styles.qFlex}>
            <div className={styles.sexInput}>
                <div onClick={() => setOrganic(1)}>
                    <div className={organic === 1 ? styles.fullRadioCircle : styles.emptyRadioCircle}/>
                    <SText size={16} lineHeight={16} weight={500}>{'Да'}</SText>
                </div>
                <div onClick={() => setOrganic(2)}>
                    <div className={organic === 2 ? styles.fullRadioCircle : styles.emptyRadioCircle}/>
                    <SText size={16} lineHeight={16} weight={500}>{'Нет'}</SText>
                </div>
            </div>
            <div className={styles.qPoints}>
                <div className={organic === '' && styles.emptyCircle}>{organic !== '' &&
                    <img src={fullTick}/>}</div>
                <div className={organic === '' ? styles.amountGray : styles.amountGreen}>{'+20'}</div>
            </div>
        </div>
    </div>
}*/

const Socials = ({socials, setSocials}) => {

    return <div className={styles.qWrapper}>
        <div style={{display: 'flex', gap: 3}}
             className={styles.qTitle}>17. <span>{' Какими соц сетями вы больше всего'} <br/> {'пользуетесь?'}</span>
        </div>
        <div className={styles.qFlex}>
            <div className={styles.pets}>
                {
                    socialsArray.map(({value, name}) => <div onClick={() => {
                        if (socials.includes(value))
                            setSocials(socials.filter((f) => f !== value))
                        else setSocials([...socials, value])
                    }} className={styles.petRow}>
                        <img src={socials.includes(value) ? checkboxFull : checkbox}/>
                        <SText size={15} weight={500} lineHeight={15}>{name}</SText>
                    </div>)
                }
            </div>
            <div className={styles.qPoints}>
                <div className={socials.length <= 0 && styles.emptyCircle}>{socials.length > 0 &&
                    <img src={fullTick}/>}</div>
                <div className={socials.length <= 0 ? styles.amountGray : styles.amountGreen}>{'+50'}</div>
            </div>
        </div>
    </div>
}

const Inst = ({inst, setInst}) => {

    return <div className={styles.qWrapper}>
        <div className={styles.qTitle}>18. <span>{' Ваш ник в социальных сетях'}</span></div>
        <div className={styles.qFlex}>
            <div className={styles.nameInput}>
                <input placeholder={'Ваш ник'} value={inst} onChange={e => setInst(e.target.value)} type="text"/>
            </div>
            <div className={styles.qPoints}>
                <div className={inst.length <= 1 && styles.emptyCircle}>{inst.length > 1 &&
                    <img src={fullTick}/>}</div>
                <div className={inst.length <= 1 ? styles.amountGray : styles.amountGreen}>{'+100'}</div>
            </div>
        </div>
    </div>
}

const PopularStores = ({popularStores, setPopularStores, otherStores}) => {
    let earned = popularStores.filter(item => item.value !== false).length > 0

    const hiddenNames = otherStores.reduce((acc, cur) => {
        if (cur.value?.length < 1)
            return [...acc, cur.fieldName]
        else return acc
    }, [])

    let visible = otherStores.length !== hiddenNames.length

    return <div style={{marginBottom: 30}} className={styles.qWrapper}>
        <div style={{marginBottom: 10}} className={styles.qTitleSpecial}>
            <div>
                <div style={{display: 'flex', gap: 5, marginBottom: 15}}>
                    <SText size={14} weight={500} lineHeight={14} color={'#818181'}>{'19.'}</SText>
                    <SText size={14} weight={700} lineHeight={14}>{'В каком магазине вы чаще'} <br/>
                        {'закупаетесь'}</SText>
                </div>
            </div>
            <div>
                <div className={styles.qPoints}>
                    <div className={!earned && styles.emptyCircle}>{earned &&
                        <img src={fullTick}/>}</div>
                    <div className={!earned ? styles.amountGray : styles.amountGreen}>{'+100'}</div>
                </div>
            </div>
        </div>

        {visible ? <div className={styles.qColumn}>
            <div className={styles.popularTitle}>
                <div><SText size={16} lineHeight={16} weight={700}>{'часто'}</SText></div>
                <div><SText size={16} lineHeight={16} weight={700}>{'редко'}</SText></div>
            </div>
            <div>
                {
                    popularStores.map(({title, fieldName, value, disabledIndex}) => {
                        if (hiddenNames.find(item => item === fieldName)) return null
                        return <div
                            className={styles.storeRow}>
                            <SText size={16} lineHeight={16} weight={500}>{title}</SText>
                            <div className={styles.multiCheckboxes}>
                                <img onClick={() => {
                                    setPopularStores(prev => prev.map(item => {
                                        if (item.fieldName !== fieldName) return item
                                        else return {
                                            ...item,
                                            value: item.value === 0 ? false : 0
                                        }
                                    }))
                                }} src={value === 0 ? checkboxFull : checkbox}/>
                                <img onClick={() => {
                                    setPopularStores(prev => prev.map(item => {
                                        if (item.fieldName !== fieldName) return item
                                        else return {
                                            ...item,
                                            value: item.value === 1 ? false : 1
                                        }
                                    }))
                                }} src={value === 1 ? checkboxFull : checkbox}/>
                            </div>
                        </div>
                    })
                }
            </div>
        </div> : <div>
            <SText size={14} weight={500} lineHeight={14}
                   color={'#818181'}>{'Пожалуйста, ответьте сначала на вопрос 11'}</SText>
        </div>}
    </div>
}

export default Anketa;