import React, {useContext, useState} from "react";
import styles from "./AccountSettings.module.css"
import {Delivery, ModalCard, UserInfo} from "../Ordering/components/Ordering";
import SText from "../../components/SText";
import {useRestrictions, useWindowDimensions} from "../../utils/hooks";
import Modal from "../../components/Modal";
import applePay from "../../assets/images/applePay.svg";
import walletModal from "../../assets/images/walletModal.svg";
import visa from "../../assets/images/visaFooter.svg";
import bankCard from "../../assets/images/bankCard.svg";
import editPencil from "../../assets/images/editPencil.svg"
import exit from "../../assets/images/exit.svg"
import DataContext from "../../data/DataContext";
import ModalContext from "../../data/ModalContext";
import OrdersHistoryHeaderMobile from "../../components/OrdersHistoryHeaderMobile";

const cards = [{id: 0, icon: applePay, isSelected: true, text: 'Apple pay'}, {
    id: 1,
    icon: walletModal,
    isSelected: false,
    text: 'Наличными курьеру'
}, {id: 2, icon: visa, isSelected: false, text: 'Карта **** **** **** 8888'}, {
    id: 3,
    icon: bankCard,
    isSelected: false,
    text: 'Visa, Master Card, Maestro, МИР'
}]

const Payment = () => {

    const {isMobile} = useWindowDimensions(650)


    const [payment, setPayment] = useState(false)
    const [activeId, setActiveId] = useState(1)

    return null

    return <div style={{marginBottom: '50px'}}>
        <div style={{marginBottom: '12px'}}><SText fontSize={14} fontWeight={400} color={'#909090'}>Способ оплаты по
            умолчанию</SText></div>

        <div className={styles.payment}>
            <div className={styles.selectedPayment}>
                <div style={{marginRight: '50px'}}><SText fontSize={16} fontWeight={700}
                                                          color={'#213140'}>{cards[activeId].text}</SText></div>
            </div>
            {payment
                ? <div className={styles.changePayment}>
                    {isMobile ? null :
                        <div style={{textAlign: 'right', marginRight: '12px'}}>
                            <SText style={{lineHeight: '2px'}}
                                   fontSize={14} fontWeight={400}
                                   color={'#909090'}>Редактировать <br/> способы
                                оплаты</SText></div>}
                    <div><img src={editPencil}/></div>
                    <Modal modalTitle={'ВЫБОР СПОСОБА ОПЛАТЫ'} isVisible={payment}
                           onClose={() => setPayment(false)}>
                        <div style={{}}>
                            {cards.map(card => <ModalCard setPayment={setPayment} key={card.id}
                                                          setActiveId={setActiveId}
                                                          activeId={activeId} id={card.id} icon={card.icon}
                                                          text={card.text}/>)}
                        </div>
                    </Modal>
                </div>
                : <div onClick={() => setPayment(true)} className={styles.changePayment}>
                    {isMobile ? null :
                        <div style={{textAlign: 'right', marginRight: '12px'}}>
                            <SText style={{lineHeight: '2px'}} fontSize={14}
                                   fontWeight={400} color={'#909090'}>
                                Редактировать <br/> способы оплаты
                            </SText>
                        </div>}
                    <div><img src={editPencil}/></div>
                </div>
            }
        </div>
    </div>
}

const AccountSettings = ({onBack=()=>{}}) => {

    const {isMobile, windowDimensions: {width}} = useWindowDimensions(650)

    const {signOut} = useContext(DataContext)
    const {open: _open, close} = useContext(ModalContext)
    const open = useRestrictions(_open)

    return <div className={styles.container} style={isMobile ? {margin: '0 0'} : {margin: '35px 0 0 50px'}}>
        {isMobile && <OrdersHistoryHeaderMobile title={'Настройки'}
                                                onBack={onBack}
                                                onClose={()=>close('user')}/>}
        {isMobile && <div style={{padding: '0 20px'}}>
            <SText fontSize={34} fontWeight={900}>Настройки</SText>
        </div>}
        <UserInfo/>
        <Delivery account={true}/>
        <div style={{padding: '0 20px'}}>
            <Payment/>
            <div onClick={()=>{
                open('exit')
            }} style={{display: 'flex', alignItems: 'center'}}>
                <img style={{width: '22px', height: '22px', marginRight: '14px'}} src={exit}/>
                <div style={{cursor: 'pointer'}} ><SText color={'#FF5252'} fontSize={18} fontWeight={700}>Выйти</SText></div>
            </div>
        </div>
    </div>
}

export default AccountSettings
