import React from "react";
import styles from '../styles.module.css'
import SText from "../../../components/SText";
import RoundedButton from "../../../components/RoundedButton";
import TouchableOpacity from "../../../components/TouchableOpacity";
import {useHistory} from "react-router-dom";
import warning from '../../../assets/images/warning.svg'
import Icon from "../../../components/Icon";


const ScreenFail = ({orderId=1, onTryAgain=()=>{}}) => {

    const {push} = useHistory()

    return <div className={styles.container}>
        <div className={styles.constraints}>
            <div className={styles.info}>
                <SText fontSize={28} fontWeight={900}    gap>{`НЕ УДАЛОСЬ ОПЛАТИТЬ ЗАКАЗ НОМЕР\u00A0${orderId}`}</SText>
                <div className={styles.checkContainer}>
                    <div className={styles.checkOuter} style={{backgroundColor: 'rgba(255,52,52,.4)'}}>
                        <Icon iconSrc={warning} size={160}/>
                    </div>
                </div>
            </div>
            <RoundedButton label={'Попробовать снова'} onPress={onTryAgain}/>
            <TouchableOpacity style={{alignSelf: 'center', marginTop: 32}} onClick={() => push('/')}>
                <SText color={'#bdbdbd'} fontWeight={700} fontSize={14}
                       style={{textDecoration: 'underline', alignSelf: 'center'}}>
                    {'Оплатить позже'}
                </SText>
            </TouchableOpacity>
        </div>
    </div>
}

export default ScreenFail
