import React, {useEffect, useState} from "react";
import cn from "classnames";
import styles from "./Burger.module.css";
import {useEscape} from "../utils/hooks";
import TouchableOpacity from "./TouchableOpacity";
import closeIcon from "../assets/images/closeButton.svg";
import Div100vh, {use100vh} from 'react-div-100vh'
import {bodyLocker} from "../utils/other";

const Burger = ({
                    isVisible = false, onClose = () => {
    }, modalTitle = '', ...otherProps
                }) => {

    const [isShown, setShown] = useState(isVisible)

    const height = use100vh()

    const [mounted, setMounted] = useState(false)

    const overLayRef = useEscape(styles.overlay, onClose)

    useEffect(()=>{
       // mounted ? bodyLocker.enable() : bodyLocker.disable()
    }, [mounted])

    useEffect(() => {
        if (isVisible) {
            setShown(true)
            setTimeout(() => {
                setMounted(true)
            }, 100)
        } else {
            setTimeout(() => {
                setShown(false)
                setMounted(false)
            }, 350)
        }
    }, [isVisible])

    if (!isShown) return null
    return <Div100vh className={cn(styles.overlay, (!isVisible || !mounted) && styles.overlayInvisible)}
                ref={overLayRef}
    >

        <Div100vh style={{height: '100%'}}
             className={cn(styles.container, (!isVisible || !mounted) && styles.containerInvisible)}>
                {otherProps.children}
        </Div100vh>

        <TouchableOpacity onClick={onClose}>
            <div className={styles.circle} style={{zIndex: '1000'}}>
                <img src={closeIcon}/>
            </div>
        </TouchableOpacity>

    </Div100vh>
}

export default Burger
