import React from "react";
import styles from './SelectInput.module.css'
import Icon from "./Icon";
import arrow from '../assets/images/chevronDown.svg'
import {removeYear} from "../utils/other";

const SelectInput = ({options=[], value, onSelect=console.log}) => {
    return <div style={{position: 'relative', width: 'fit-content'}}>
        <select className={styles.selectInput} onChange={(e) => onSelect(e.target.value)}>
            {options.map(option => {
                return <option value={option.value}>
                    {removeYear(option?.label)}
                </option>
            })}
        </select>
        <Icon iconSrc={arrow} size={12} className={styles.arrow}/>
    </div>
}

export default SelectInput
