import React, {useContext} from "react";
import exit from "../assets/images/exit.svg"
import SText from "./SText";
import RoundedButton from "./RoundedButton";
import styles from "./Exit.module.css"
import Colors from "../utils/Colors";
import {useWindowDimensions} from "../utils/hooks";
import DataContext from "../data/DataContext";
import ModalContext from "../data/ModalContext";

export default ({onClose}) => {

    const {isMobile, windowDimensions:{width}} = useWindowDimensions()
    const {signOut} = useContext(DataContext)
    const {open: _open, close} = useContext(ModalContext)

    if (isMobile) {
        return <div className={styles.wrapper}>
            <img style={{marginBottom:'25px'}} src={exit}/>
            <SText style={{textAlign:'center', marginBottom:'30px'}} fontSize={18} fontWeight={700}>Вы уверены, что хотите выйти со своего аккаунта?</SText>
            <RoundedButton onPress={() => {close('exit')}} activeColor={Colors.orange} containerStyle={{marginBottom:'25px', width:'259px', height:'50px'}} label={'Отмена'}/>
            <RoundedButton onPress={() => {signOut(); close('user'); close('exit')}} containerStyle={{width:'259px', height:'50px'}} label={'Да, выйти'}/>
        </div>
    } else return <div className={styles.wrapper}>
        <img style={{marginBottom:'30px'}} src={exit}/>
        <SText style={{textAlign:'center', marginBottom:'30px'}} fontSize={18} fontWeight={700}>Вы уверены, что хотите выйти со своего аккаунта?</SText>
        <RoundedButton onPress={() => {close('exit')}} activeColor={Colors.orange} containerStyle={{marginBottom:'25px', width:'259px', height:'50px'}} label={'Отмена'}/>
        <RoundedButton onPress={() => {signOut(); close('user');close('exit')}} containerStyle={{width:'259px', height:'50px'}} label={'Да, выйти'}/>
    </div>
}