import React, {useMemo} from "react";
import SText from "./SText";
import styles from "./AboutSeason.module.css"
import Header from "./Header";
import MobileExtraInfo from "../screens/Catalogue/components/MobileExtraInfo";
import {useWindowDimensions} from "../utils/hooks";
import BreadCrumbs from "./BreadCrumbs";
import {Helmet} from "react-helmet";

export default () => {

    const {isMobile, windowDimensions:{width}} = useWindowDimensions(600)

    const breadCrumbs = [
            {
                name: 'О SeasonMarket',
                to: '/about-season-market'
            }
        ]


    return <div>
        <Helmet>
            <title>О SeasonMarket</title>
            <meta name="description" content='О SeasonMarket'/>
            <meta name="keywords" content='О SeasonMarket'/>
        </Helmet>
        <Header/>
        {width < 992 && <MobileExtraInfo/>}
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <BreadCrumbs _items={breadCrumbs}/>
                <div style={{marginBottom: '35px'}}><SText fontSize={24} fontWeight={900}>О SeasonMarket</SText></div>
                <div style={{marginBottom: '15px'}}><SText fontSize={16} fontWeight={900}>Что такое – «SeasonMarket»?
                    Это сервис, который проложил связи
                    между покупателями и производителями натуральных продуктов – самых вкусных, свежих, качественных и
                    безопасных. <br/> Мы находим их для вас, и в этом – наша задача.</SText></div>
                <div className={styles.li}><SText fontSize={16} fontWeight={550}>С нашей помощью покупка приобретает
                    максимально удобную для вас
                    форму:</SText></div>
                <div className={styles.li}><SText fontSize={16} fontWeight={550}>Вы заказываете сегодня – и мы
                    доставляем продукты к вам домой уже
                    завтра! Вы можете выбрать удобный временной интервал доставки при оформлении заказа. Оставьте
                    пожелания
                    в комментариях, и мы постараемся их выполнить.</SText></div>
                <div className={styles.li}><SText fontSize={16} fontWeight={550}>Оставаться в неведении о точной сумме и
                    составе заказа?
                    Ознакомиться с весом заказа и суммой к оплате лишь в день доставки? Этого не будет! Вы узнаете
                    точную
                    информацию о составе своего заказа в течение короткого времени.</SText></div>
                <div className={styles.li}><SText fontSize={16} fontWeight={550}>Мы заботимся о том, чтобы вам не
                    пришлось самостоятельно искать
                    лучшие натуральные продукты у разных производителей. В ассортимент SeasonMarket регулярно
                    добавляются
                    новые позиции — мы думаем о разнообразии вашего меню!</SText></div>
                <div className={styles.li}><SText fontSize={16} fontWeight={550}>С нами выбирать продукты и готовить
                    изысканные блюда так легко!
                    Ведь каждая позиция ассортимента SeasonMarket уже подготовлена к кулинарной обработке. Индейка
                    продается
                    уже разделанной, а рыба будет выпотрошена и почищена по вашему желанию.</SText></div>
                <div className={styles.li}><SText fontSize={16} fontWeight={550}>Продукты в современной, надежной и
                    аккуратной упаковке – мы
                    соблюдаем все критерии безопасной торговли.</SText></div>
                <div className={styles.li}><SText fontSize={16} fontWeight={550}>Мы предлагаем только самые свежие
                    продукты: фасовка под заказ,
                    реализация до истечения 2/3 срока годности, качественная отбраковка, доставка в автомобилях,
                    оборудованных профессиональными рефрижераторами.</SText></div>
                <div><SText fontSize={16} fontWeight={700}>Вы, как и многие, считаете, что натуральные продукты – это
                    всегда
                    дорого? У нас тщательный подход к формированию цены на продукцию. Мы предлагаем: качество продуктов
                    –
                    высочайшее, а цены на них – разумные и честные.</SText></div>
            </div>
        </div>
    </div>
}