import React from "react";
import styles from "./BonusSystem.module.css"
import {Helmet} from "react-helmet";
import Header from "./Header";
import MobileExtraInfo from "../screens/Catalogue/components/MobileExtraInfo";
import {useWindowDimensions} from "../utils/hooks";
import BreadCrumbs from "./BreadCrumbs";
import SText from "./SText";
import Colors from "../utils/Colors";

export default () => {

    const {isMobile, windowDimensions: {width}} = useWindowDimensions(600)

    const breadCrumbs = [
        {
            name: 'Бонусная система',
            to: '/bonus-system'
        }
    ]

    return <div>
        <Helmet>
            <title>Бонусная система</title>
            <meta name="description" content='Бонусная система'/>
            <meta name="keywords" content='Бонусная система'/>
        </Helmet>
        <Header/>
        {width < 992 && <MobileExtraInfo/>}
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <BreadCrumbs _items={breadCrumbs}/>
                <div style={{marginBottom: width > 762 ? 30 : 30}} className={styles.title}><SText fontSize={24}
                                                                                                   lineHeight={23}
                                                                                                   fontWeight={900}>Бонусная
                    система</SText></div>

                <div><SText fontSize={16} fontWeight={550} lineHeight={18}>Копите бонусы за каждый оформленный заказ –
                    оплачивайте до 50% от суммы следующей покупки. </SText></div>
                <div><SText fontSize={16} fontWeight={550} lineHeight={18}>1 бонус = 1 рубль.</SText></div>
                <div style={{marginBottom: width > 762 ? 28 : 18}}><SText fontSize={16} fontWeight={550}
                                                                          lineHeight={18}>Бонусы на счёт зачисляются
                    сразу после оплаты и сгорают через 30 дней.</SText></div>

                <div style={{marginBottom: width > 762 ? 22 : 42}}><SText fontSize={16} fontWeight={900}
                                                                          lineHeight={15}>Как можно получить
                    бонусы:</SText></div>

                <div className={styles.dot} style={{marginBottom: width > 762 ? 27 : 22}}><SText fontSize={16}
                                                                                                 fontWeight={550}
                                                                                                 lineHeight={18}>за
                    заказ – 5% от общей суммы;</SText></div>

                <div className={styles.dot} style={{marginBottom: width > 762 ? 30 : 30}}><SText fontSize={16}
                                                                                                 fontWeight={550}
                                                                                                 lineHeight={18}>за то,
                    что поделились с друзьями промокодом из Личного кабинета – 300 бонусов за каждого друга,
                    применившего промокод.</SText></div>

                <div><SText fontSize={16} fontWeight={700} lineHeight={18}>Мы любим вас радовать! Заказывайте
                    натуральные продукты с доставкой до порога на интернет-рынке SeasonMarket. Вам обязательно
                    понравится!</SText></div>
            </div>
        </div>
    </div>
}