import React from "react";
import SText from "./SText";
import TouchableOpacity from "./TouchableOpacity";
import Icon from "./Icon";
import {circle} from "../utils/mixins";
import styles from './ModalHeader.module.css'
import closeIcon from '../assets/images/closeButton.svg'

const ModalHeader = ({onClose=()=>{}, title=''}) => {
    return <div className={styles.container}>
        <SText fontWeight={900} fontSize={28}>{title}</SText>
        <TouchableOpacity style={circle(32, '#f3f3f3')} onClick={onClose}>
            <Icon iconSrc={closeIcon} size={18}/>
        </TouchableOpacity>
    </div>
}

export default ModalHeader
