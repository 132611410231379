import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./Header.module.css"
import whatsapp from '../assets/images/whatsapp.svg'
import logo from '../assets/images/logo.svg'
import logoMobile from '../assets/images/newLogoMobile.svg'
import SText from "./SText";
import Colors from "../utils/Colors";
import logoSmall from '../assets/images/newPreloader.svg'
import basket from '../assets/images/shoppingCartHeader.svg'
import regionHor from "../assets/images/yourRegionHorizontal.png"
import DataContext from "../data/DataContext";
import cn from "classnames";
import TouchableOpacity from "./TouchableOpacity";
import { useRestrictions, useWindowDimensions } from "../utils/hooks";
import { NavLink, useHistory } from "react-router-dom";
import Burger from "./Burger";
import HeaderBurger from "./HeaderBurger";
import ModalContext from "../data/ModalContext";
import { SearchInput, SearchPopupDesktop } from "./Search";
import moment from "moment";
import { inputFormat } from "../config";
import { removeYear } from "../utils/other";
import { RegionContext } from "../App";

const Header = ({ query = '' }) => {
    const { userLocation, catalogue, cart, user, getIsAuth, deliverySlots, auth } = useContext(DataContext)
    const { region, setRegion } = useContext(RegionContext)
    const [askForRegion, setAskForRegion] = useState(!userLocation.data)
    const [isScrolled, setScrolled] = useState(false)

    const { open: _open } = useContext(ModalContext)

    const regionName = region === 77 ? 'Москва' : 'Санкт-Петербург'

    const open = useRestrictions(_open)
    const { push } = useHistory()

    const { end } = deliverySlots.data?.slots[0] || {}
    const { start } = deliverySlots.data?.slots[0] || {}

    const nearestDeliveryEnd = end ? removeYear(moment(end, inputFormat).format('LT')) : false
    const nearestDeliveryStart = start ? removeYear(moment(start, inputFormat).format('LLL')) : false

    const nearestDelivery = `${nearestDeliveryStart} - ${nearestDeliveryEnd}`

    const cartItems = cart.data?.productList || []

    const { windowDimensions: { width } } = useWindowDimensions()

    function onScroll() {
        const offset = window.scrollY
        setScrolled(offset > 76)
    }

    useEffect(() => {
        window.addEventListener('scroll', onScroll)
        setTimeout(() => {
            if (!userLocation.data?.address)
                setAskForRegion(true)
        }, 1000)
        setTimeout(() => {
            if (!userLocation.data?.address)
                setAskForRegion(false)
        }, 6000)
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    }, [])

    const [burgerVisible, setBurgerVisible] = useState(false)

    const catsList = useRef(null)

    const onCategoryClick = (id) => {
        setTimeout(() => window.scrollTo(0, 0), 100)
        if (!auth.data?.auth) {
            window.mindbox("async", {
                operation: "OnlineCategoryView",
                data: {
                    viewProductCategory: {
                        productCategory: {
                            ids: {
                                sMProductID: id
                            }
                        }
                    }
                }
            });
        }
    }


    if (width <= 992) {
        return <header className={ cn(styles.wrapper, styles.opaque) }>
            <Burger isVisible={ burgerVisible } onClose={ () => setBurgerVisible(false) }>
                <HeaderBurger onClose={ () => setBurgerVisible(false) } />
            </Burger>
            <div className={ styles.header }>
                <div className={ styles.mobileContainer }>

                    <div onClick={ () => setBurgerVisible(true) } style={ { padding: '7px 0' } }>
                        <label className={ styles.menuBtn }>
                            <div />
                        </label>
                    </div>
                    <NavLink to={ '/' } onClick={ () => setTimeout(() => window.scrollTo(0, 0), 100) }>
                        <div className={ styles.logoMobile }>
                            <img src={ logoMobile } />
                        </div>
                    </NavLink>
                    <TouchableOpacity>
                        <div onClick={ () => _open('cart') } style={ { position: 'relative' } }>
                            <div className={ styles.basketCircle }>
                                <img src={ basket } />
                            </div>
                            { cartItems.length ? <div className={ styles.budge }>{ cartItems.length }</div> : null }
                        </div>
                    </TouchableOpacity>
                </div>
            </div>
        </header>
    } else {
        return <div className={ cn(styles.main, isScrolled && styles.mainShrink) }>
            <div className={ styles.container }>
                <div className={ cn(styles.info, isScrolled && styles.infoHidden) }>
                    <NavLink onClick={ () => setTimeout(() => window.scrollTo(0, 0), 100) } to={ '/' }>
                        <div className={ styles.logo }>
                            <img src={ logo } />
                        </div>
                    </NavLink>
                    <div className={ styles.middleBlock }>
                        <div className={ styles.discount }>
                            <SText fontSize={ 18 } fontWeight={ 900 } color={ Colors.mainGreen }>{ 'СКИДКА 500 РУБ ' }</SText>
                            <SText fontSize={ 18 } fontWeight={ 550 } color={ Colors.mainGreen }>{ 'ПРИ ЗАКАЗЕ НА ' }</SText>
                            <SText fontSize={ 18 } fontWeight={ 900 } color={ Colors.mainGreen }>{ '7000 РУБ ' }</SText>
                            <SText fontSize={ 18 } fontWeight={ 550 } color={ Colors.mainGreen }>{ 'И' }</SText><br></br>
                            <SText fontSize={ 18 } fontWeight={ 900 } color={ Colors.mainGreen }>{ '1000 РУБ ' }</SText>
                            <SText fontSize={ 18 } fontWeight={ 550 } color={ Colors.mainGreen }>{ 'ПРИ ЗАКАЗЕ НА ' }</SText>
                            <SText fontSize={ 18 } fontWeight={ 900 } color={ Colors.mainGreen }>{ '10 000 РУБ ' }</SText><br />
                            <SText fontSize={ 18 } fontWeight={ 550 } color={ Colors.mainGreen }>{ 'ПО ПРОМОКОДАМ ' }</SText>
                            <SText fontSize={ 18 } fontWeight={ 900 } color={ Colors.mainGreen }>{ 'SM500 ' }</SText>
                            <SText fontSize={ 18 } fontWeight={ 550 } color={ Colors.mainGreen }>{ 'И ' }</SText>
                            <SText fontSize={ 18 } fontWeight={ 900 } color={ Colors.mainGreen }>{ 'SM1000' }</SText>
                        </div>
                        <NavLink style={ { width: '100%', maxWidth: '435px' } }
                            onClick={ () => setTimeout(() => window.scrollTo(0, 0), 100) }
                            to={ "/delivery-and-payment" }>
                            { nearestDelivery && <div className={ styles.delivery }>
                                <span>{ 'Ближайшая доставка - ' }</span>
                                <div className={ styles.date }><span>{ nearestDelivery }</span></div>
                            </div> }
                        </NavLink>
                    </div>
                    <div className={ styles.contacts }>
                        <a style={ { marginBottom: '5px' } } href="tel:+74952155144">
                            <SText fontWeight={ 400 } fontSize={ 20 }
                                color={ Colors.darkBlue }>{ '+7 (495) 215-51-44' }</SText>
                        </a>
                        <a style={ { marginBottom: '11px' } } target="_blank" href="https://wa.me/74952155144"><SText><SText
                            style={ { marginLeft: '-24px', paddingRight: '6px' } }>
                            <img src={ whatsapp } /></SText><SText fontWeight={ 400 } fontSize={ 20 }
                                color={ Colors.darkBlue }>{ '+7 (495) 215-51-44' }</SText></SText></a>
                        <SText fontSize={ 14 } fontWeight={ 550 } color={ '#D9D9D9' }
                            style={ { textAlign: 'right' } }>{ 'ежедневно с 8:00 до 20:00' }</SText>
                    </div>
                </div>
                <div className={ cn(styles.found, isScrolled && styles.foundShrink) }>
                    <img src={ logoSmall } onClick={ () => setTimeout(() => window.scrollTo(0, 0), 100) }
                        className={ cn(styles.logoSmall, isScrolled && styles.logoSmallOpaque) } />
                    <div className={ cn(styles.firstBlock, isScrolled && styles.firstBlockShrink) }>
                        <SearchInput query={ query } />
                        <div onClick={ () => _open('region') } className={ styles.firstBlockInner }>
                            <div className={ styles.city }>
                                <SText fontSize={ 18 } fontWeight={ 400 }
                                    color={ '#BDBDBD' }>{ 'Ваш город: ' }</SText> {/*todo*/ }
                                <SText fontSize={ 18 } fontWeight={ 400 } className={ styles.region }
                                    color={ '#BDBDBD' }>{ regionName }</SText>
                            </div>
                            <div className={ cn(styles.bubble, cn(styles.bubbleImg, !askForRegion && styles.fadeOut)) }>
                                <img className={ styles.bubbleInner } src={ regionHor } />
                            </div>
                        </div>
                    </div>
                    <div className={ styles.secondBlock }>
                        <TouchableOpacity>
                            <div className={ styles.user } onClick={ () => {
                                open('user')
                            } }>
                                <div>
                                    <SText fontSize={ 18 } fontWeight={ 500 }
                                        style={ { marginRight: '12px' } }>{ getIsAuth() ? (user.data?.full_name || 'Личный кабинет') : 'Войти' }</SText>
                                </div>
                            </div>
                        </TouchableOpacity>
                        <TouchableOpacity>
                            <div onClick={ () => _open('cart') } style={ { position: 'relative' } }>
                                <div className={ styles.basketCircle }>
                                    <img src={ basket } />
                                </div>
                                { cartItems.length ? <div className={ styles.budge }>{ cartItems.length }</div> : null }
                            </div>
                        </TouchableOpacity>
                    </div>
                </div>
                <div className={ styles.searchPopupAnchor }>
                    <SearchPopupDesktop mt={ -12 } />
                </div>
                <div className={ styles.category } ref={ catsList }>
                    { catalogue.data?.categories?.map(cat => {
                        let sub = cat.subCategories[0] ? '/' + cat.subCategories[0]?.url : ''

                        const isActive = () => {
                            let url = window.location.href
                            let splits = url.split('/');
                            let is = splits.findIndex(el => el === cat.url)
                            return is !== -1
                        }
                        return <NavLink to={ '/catalog/' + cat.url + sub } className={ isActive() ? styles.linkActive : null }
                            onClick={ () => onCategoryClick(cat.id) }>
                            <div className={ styles.categoryItem }>
                                <SText fontSize={ 14 } fontWeight={ 500 }
                                    color={ '#000' }>{ cat.name?.toUpperCase() }</SText>
                            </div>
                        </NavLink>
                    }) }
                </div>

            </div>
        </div>
    }
}

export default Header
