import React, {useContext} from "react";
import SText from "../../components/SText";
import zaglushka from "../../assets/images/zaglushka.svg"
import edit from "../../assets/images/accountEdit.svg"
import styles from "./AccountHeader.module.css"
import TouchableOpacity from "../../components/TouchableOpacity";
import closeIcon from "../../assets/images/closeButton.svg";
import {useWindowDimensions} from "../../utils/hooks";
import AccountBonus from "./AccountBonus";
import DataContext from "../../data/DataContext";
import cn from "classnames";
import ModalContext from "../../data/ModalContext";

export default ({onClose=()=>{}, currentSection = 0, setCurrentSection=()=>{}}) => {

    const {isMobile, windowDimensions: {width}} = useWindowDimensions(650)
    const {signOut, user} = useContext(DataContext)
    const {open} = useContext(ModalContext)



    if (isMobile) {
        return <div className={styles.wrapper}>
            <div className={styles.user}>
                {/*<TouchableOpacity>
                    <div style={{marginBottom: '33px', position: 'relative'}}>
                        <img src={zaglushka}/>
                        <div className={styles.budge}>
                            <img src={edit}/>
                        </div>
                    </div>
                </TouchableOpacity>*/}
                <div className={styles.userInfo}>
                    <SText style={{marginBottom: '10px'}} fontSize={28} fontWeight={900}>{user.data?.full_name || 'Личный кабинет'}</SText>
                    <SText fontSize={16} fontWeight={500} color={'#909090'}>{user.data?.phone || ''}</SText>
                </div>
            </div>
            <TouchableOpacity onClick={onClose}>
                <div className={styles.circle}>
                    <img src={closeIcon}/>
                </div>
            </TouchableOpacity>
            <div className={styles.nav}>
                <AccountBonus onClose={onClose}/>
                <TouchableOpacity onClick={()=>setCurrentSection(1)} style={{marginBottom: '35px'}}>
                  <SText fontSize={18} fontWeight={550}>История заказов</SText>
                </TouchableOpacity>

                <TouchableOpacity onClick={()=>setCurrentSection(2)} style={{marginBottom: '90px'}}>
                    <SText  fontSize={18} fontWeight={550}>Настройки</SText>
                </TouchableOpacity>
            </div>
            <TouchableOpacity onClick={()=>open('exit')}>
                <SText fontSize={18} fontWeight={700} color={'#FF5252'}>Выйти</SText>
            </TouchableOpacity>

        </div>
    } else return <div className={styles.wrapper}>
        <TouchableOpacity onClick={onClose}>
            <div className={styles.circle}>
                <img src={closeIcon}/>
            </div>
        </TouchableOpacity>
        <div className={styles.user}>
            {/*<TouchableOpacity> todo profile pic
                <div style={{position: 'relative', marginRight: '32px'}}>
                    <img src={zaglushka}/>
                    <div className={styles.budge}>
                        <img src={edit}/>
                    </div>
                </div>
            </TouchableOpacity>*/}
            <div className={styles.userInfo}>
                <SText style={{marginBottom: '10px'}} fontSize={28} fontWeight={900}>{user.data?.full_name || 'Личный кабинет'}</SText>
                <SText fontSize={16} fontWeight={500} color={'#909090'}>{user.data?.phone || ''}</SText>
            </div>
        </div>
        <div className={styles.nav}>
            <NavItem text={'БОНУСЫ'}            isSelected={currentSection === 0} onSelect={()=>setCurrentSection(0)}/>
            <NavItem text={'ИСТОРИЯ ЗАКАЗОВ'}   isSelected={currentSection === 1} onSelect={()=>setCurrentSection(1)}/>
            <NavItem text={'НАСТРОЙКИ'}         isSelected={currentSection === 2} onSelect={()=>setCurrentSection(2)}/>
        </div>
    </div>
}

const NavItem = ({text = '', onSelect = ()=>{}, isSelected = false}) => {
    return <div className={cn(styles.navItem, isSelected && styles.navItemActive)} onClick={onSelect}>
        <SText fontSize={18} fontWeight={550}>{text}</SText>
    </div>
}
