import React from 'react'
import Colors from "../utils/Colors";
import {useWaiting} from "../utils/hooks";
import style from "./RoundedButton.module.css"

const RoundedButton = ({
                           onPress = () => {
                           },
                           label = 'Button',
                           containerStyle = {},
                           activeColor = Colors.darkBlue,
                           isDimmed = false,
                           async = true,
                           dimmedColor = 'rgba(33,49,64,0.69)',
                           disabled = false,
                           children = null,
                           ...props
                       }) => {

    const [waiting, cb] = useWaiting(onPress)

    return <button onClick={async ? cb : onPress} disabled={disabled || waiting}
                   className={style.shape} style={{
        backgroundColor: (isDimmed || disabled) ? dimmedColor : activeColor,
        ...containerStyle
    }}

                   {...props}
    >
        {waiting ?
            <div className={style.label}>Подождите...</div> :
            (children || <div className={style.label}>{label}</div>)}
    </button>
}

export default RoundedButton
