import React, {Component, useContext, useRef} from "react";
import Slider from "react-slick";
import {BrowserRouter, Link, NavLink} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "./style.css";
import styles from "./Banner.module.css"
import left from '../assets/images/bannerButtonLeft.svg'
import right from '../assets/images/bannerButtonRight.svg'
import {useWindowDimensions} from "../utils/hooks";
import DataContext from "../data/DataContext";
import SText from "./SText";

const settings = {
    dots: true,
    infinite: true,
    speed: 300,
    autoplaySpeed: 4000,
    fade: true,
    arrows: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1
};

export default function () {
    const slider = useRef(null)
    const {isMobile, windowDimensions: {width}} = useWindowDimensions()

    const {banners} = useContext(DataContext)

    function next() {
        if (slider.current) {
            slider.current.slickNext()
        }
    }

    function prev() {
        if (slider.current) {
            slider.current.slickPrev()
        }
    }

    return (
        <div>
            <div className={styles.title}>
                <div style={{marginBottom: 16}}><SText style={{textTransform: width<=800? 'uppercase': 'none'}} fontSize={width>=800?30:22} fontWeight={900}
                                                       lineHeight={29}>{'Натуральные продукты от\u00A0фермеров'}</SText>
                </div>
                <div><SText fontSize={width>=800?18:13} fontWeight={550}
                            lineHeight={width>=800?18:14}>{'Фермерские продукты из разных уголков России и\u00A0ближнего зарубежья с доставкой на дом'}</SText>
                </div>
            </div>
            <div className='App'>
                <div className={styles.banner}>
                    <Slider ref={slider} {...settings} className="carousel">
                        {
                            banners.data.map((slide, index) => {
                                const url = slide.url_pc
                                return <NavLink to={url}>
                                    <div className={styles.image}>
                                        <img src={width <= 540 ? slide.image_mob : slide.image_pc}/>
                                    </div>
                                </NavLink>
                            })
                        }
                    </Slider>
                    {width <= 768 ? null :
                        <>
                            <div style={{position: 'absolute', top: '45%', left: '-45px'}}>
                                <div style={{}} className="button" onClick={prev}>
                                    <img src={left}/>
                                </div>
                            </div>
                            <div style={{position: 'absolute', top: '45%', right: '-45px'}}>
                                <div className="button" onClick={next}>
                                    <img src={right}/>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        </div>
    );
}