import React, {useEffect, useState} from "react";
import AccountHeader from "./AccountHeader";
import AccountSettings from "./AccountSettings";
import AccountBonus from "./AccountBonus";
import OrdersHistory from "./OrdersHistory";
import {useWindowDimensions} from "../../utils/hooks";

const Content = ({screen, onBack=()=>{}, onClose}) => {
    switch (screen) {
        case 0: return <AccountBonus onClose={onClose}/>
        case 1: return <OrdersHistory onBack={onBack}/>
        case 2: return <AccountSettings onBack={onBack}/>
        default: return null
    }
}

const PersonalAccount = ({onClose=()=>{}, history=false}) => {
    const {isMobile} = useWindowDimensions(650)
    const [currentSection, setCurrentSection] = useState(isMobile ? -1 : 0)

    function goBack () {
        setCurrentSection(-1)
    }

    useEffect(()=>{
        if(history) setCurrentSection(1)
    }, [])

    return <div style={{height: '100vh', maxHeight: 840}}>
        {(!isMobile || currentSection === -1) && <AccountHeader currentSection={currentSection} setCurrentSection={setCurrentSection} onClose={onClose}/>}
        <Content onClose={onClose} screen={currentSection} onBack={goBack}/>
    </div>
}

export default PersonalAccount
