import styles from "./TouchableOpacity.module.css"
import cn from 'classnames';
import React, {useRef} from "react";

const TouchableOpacity =  React.forwardRef(({children, className, underlyingColor='transparent', disabled,onClick=()=>{}, ...otherProps}, ref) => {
    //
    // eslint-disable-next-line react-hooks/rules-of-hooks
    ref = ref ? ref : useRef();


    return (
        <div
            className={cn(styles.element, className)}
            ref={ref}
            onClick={onClick}
            {...otherProps}
        >
            {children}
        </div>
    );
});

export default TouchableOpacity;