import React, {useContext, useState} from "react";
import styles from "./AccountBonus.module.css"
import SText from "../../components/SText";
import copy from "../../assets/images/copy.svg"
import TouchableOpacity from "../../components/TouchableOpacity";
import {useWindowDimensions} from "../../utils/hooks";
import info from "../../assets/images/info.svg"
import NewModal from "../../components/NewModal";
import RoundedButton from "../../components/RoundedButton";
import closeIcon from "../../assets/images/closeButton.svg";
import DataContext from "../../data/DataContext";
import {declOfNum} from "../../utils/other";
import anketaRef from '../../assets/images/anketa/anketaRef.svg'
import {NavLink} from "react-router-dom";

const PromoModal = ({onClose}) => {

    return <div className={styles.modalWrapper}>
        <TouchableOpacity onClick={onClose}>
            <div className={styles.circle}>
                <img src={closeIcon}/>
            </div>
        </TouchableOpacity>
        <SText style={{marginBottom: '25px'}} fontSize={20} fontWeight={700}>А это ваш промокод!</SText>
        <SText style={{marginBottom: '30px'}} fontSize={16} fontWeight={550}>Подарите другу 300 бонусов <br/>
            на первый заказ и получите <br/>
            300 бонусов на свой счёт. <br/>
            Для этого поделитесь промокодом <br/> со своими друзьями и ожидайте, <br/> когда они оформят заказ!</SText>
        <div style={{width: '259px', height: '50px'}}><RoundedButton onPress={onClose} label={'Ясно'}/></div>
    </div>
}

export default ({onClose}) => {

    const {isMobile, windowDimensions: {width}} = useWindowDimensions(650)

    const {user} = useContext(DataContext)

    const wordForms = ['бонус', 'бонуса', 'бонусов']

    const [mv, smv] = useState(false)


    if (isMobile) {
        return <div className={styles.wrapper}>
            <div className={styles.bonusInfo}>
                {!user.data.isPolled && <div className={styles.anketa}>
                    <div className={styles.anketaInfo}>
                        <img src={anketaRef}/>
                        <SText fontSize={14} fontWeight={900} color={'#2dcc70'}
                               lineHeight={14}>{'Заполните анкету и получите '} <SText fontSize={22} lineHeight={22}
                                                                                       color={'#2dcc70'}
                                                                                       fontWeight={900}>{'1200 бонусов!'}</SText></SText>
                    </div>
                    <NavLink onClick={() => {
                        window.scrollTo(0, 0)
                        onClose()
                    }} to={'/closer'}>
                        <div className={styles.anketaRef}>
                            <SText>{'Перейти к анкете >>'}</SText>
                        </div>
                    </NavLink>
                </div>}

                <SText style={{marginBottom: '9px'}} fontSize={14} fontWeight={550}>На личном счёте:</SText>
                <SText style={{marginBottom: '20px'}} fontSize={28}
                       fontWeight={900}>{user.data?.scores || 0} {declOfNum(user.data?.scores || 0, wordForms)}</SText>
                <SText style={{lineHeight: '1.2', marginBottom: '15px'}} fontSize={14} fontWeight={500}
                       color={'#909090'}>1 бонус = 1
                    рубль<br/>Ими можно оплатить до 50%
                    заказа.<br/> Сгорают по истечении 30 дней.</SText>
                {/* <SText style={{marginBottom: '8px'}} fontSize={14} fontWeight={700} color={'#FF5252'}>140 бонусов сгорят
                    через 3 дня</SText>
                <SText style={{marginBottom: '35px'}} fontSize={14} fontWeight={400} color={'#2DCC70'}>205 бонусов
                    сгорят
                    через 20 дней</SText>*/}
                <div style={{marginBottom: '7px', display: 'flex', alignItems: 'center'}}>
                    <SText style={{marginRight: 15}} fontSize={14} fontWeight={550}>Ваш промокод для друзей:</SText>
                    <div onClick={() => smv(true)} className={styles.promoModal}><img src={info}/></div>
                </div>
                <NewModal maxHeight={318} maxWidth={301} isVisible={mv} onClose={() => smv(false)}>
                    <PromoModal onClose={() => smv(false)}/>
                </NewModal>
                <div className={styles.promoForFriends}>
                    <input style={{marginRight: 15}} disabled={true} type="text"
                           value={user.data?.personal_promocode || ''}/>
                    <TouchableOpacity>
                        <div className={styles.copyCircle}>
                            <img src={copy}/>
                        </div>
                    </TouchableOpacity>
                </div>
            </div>
        </div>
    } else return <div className={styles.wrapper}>
        <div className={styles.bonusInfo}>
            {!user.data.isPolled && <div className={styles.anketa}>
                <div className={styles.anketaInfo}>
                    <img src={anketaRef}/>
                    <SText fontSize={14} fontWeight={900} color={'#2dcc70'}
                           lineHeight={14}>{'Заполните анкету и получите '} <SText fontSize={22} lineHeight={22}
                                                                                   color={'#2dcc70'}
                                                                                   fontWeight={900}>{'1200 бонусов!'}</SText></SText>
                </div>
                <NavLink onClick={() => {
                    window.scrollTo(0, 0)
                    onClose()
                }} to={'/closer'}>
                    <div className={styles.anketaRef}>
                        <SText>{'Перейти к анкете >>'}</SText>
                    </div>
                </NavLink>
            </div>}

            <SText style={{marginBottom: '12px'}} fontSize={14} fontWeight={500}>На личном счёте:</SText>
            <SText style={{marginBottom: '25px'}} fontSize={28}
                   fontWeight={900}>{user.data?.scores || 0} {declOfNum(user.data?.scores || 0, wordForms)}</SText>
            <SText style={{marginBottom: '15px'}} fontSize={14} fontWeight={550} color={'#909090'}>1 бонус = 1
                рубль<br/>Ими можно оплатить до 50%
                заказа.<br/> Сгорают по истечении 30 дней.</SText>
            {/*<SText style={{marginBottom: '8px'}} fontSize={14} fontWeight={700} color={'#FF5252'}>140 бонусов сгорят
                через 3 дня</SText>
            <SText style={{marginBottom: '35px'}} fontSize={14} fontWeight={400} color={'#2DCC70'}>205 бонусов сгорят
                через 20 дней</SText>*/}
            <SText style={{marginBottom: '15px'}} fontSize={14} fontWeight={550}>Ваш промокод для друзей:</SText>
            <div className={styles.promoForFriends}>
                <input style={{marginRight: 15}} disabled={true} type="text"
                       value={user.data?.personal_promocode || ''}/>
                <TouchableOpacity>
                    <div onClick={() => navigator.clipboard.writeText(user.data?.personal_promocode || '')}
                         className={styles.copyCircle}>
                        <img src={copy}/>
                    </div>
                </TouchableOpacity>
            </div>
        </div>
        <div className={styles.promoInfo}>
            <SText style={{marginBottom: '10px'}} fontSize={20} fontWeight={700}>А это ваш промокод!</SText>
            <SText fontSize={14} fontWeight={550}>Подарите другу 300 бонусов на первый заказ<br/>и получите 300 бонусов
                на свой счёт.<br/>
                Для этого поделитесь промокодом со<br/>своими друзьями и ожидайте, когда они<br/>оформят заказ!</SText>
        </div>
    </div>
}
