import React, {useContext, useEffect, useState} from "react";
import SText from "../../components/SText";
import open from "../../assets/images/change.svg"
import styles from "./OrdersHistory.module.css"
import {UnmountClosed} from "react-collapse/lib/UnmountClosed";
import OrderIsProcessed from "../../components/OrderIsProcessed";
import close from "../../assets/images/closeOrderInfo.svg"
import {useWindowDimensions} from "../../utils/hooks";
import OrdersHistoryHeaderMobile from "../../components/OrdersHistoryHeaderMobile";
import ModalContext from "../../data/ModalContext";
import DataContext from "../../data/DataContext";
import {getOrder} from "../../api";
import {useHistory} from "react-router-dom";
import warning from "../../assets/images/warning.png"

export const InfoAboutOrder = ({currentStatus, orderNumber}) => {

    function getTitleText() {
        switch (currentStatus) {
            case 0:
                return 'ЗАКАЗ СОБРАН'
            case 1:
                return 'ЗАКАЗ ПЕРЕДАН НА ДОСТАВКУ'
            case 2:
                return 'ЗАКАЗ ДОСТАВЛЕН'
            case 3:
                return 'ЗАКАЗ ОТМЕНЁН'
            default:
                return 'ЗАКАЗ ПРИНЯТ'
        }
    }

    return <div>
        <OrdersHistoryHeaderMobile title={'Информация о заказе'}/>
        <div style={{margin: '30px auto 0', maxWidth: '354px'}}>
            <div style={{marginBottom: '20px'}}><SText fontSize={14} fontWeight={550}>{`Номер ${orderNumber}`}</SText>
            </div>
            <div style={{marginBottom: '30px'}}><SText fontSize={28} fontWeight={900}>{getTitleText()}</SText></div>
            <OrderIsProcessed ordersHistory={true}/>
        </div>
    </div>
}

function getTitleText(status) {
    switch (status) {
        case 'created':
            return 'ЗАКАЗ ПРИНЯТ'
        case 'handed_over_for_picking':
            return 'ЗАКАЗ СОБРАН'
        case 'on_the_way':
            return 'ПЕРЕДАН НА ДОСТАВКУ'
        case 'canceled':
            return 'ЗАКАЗ ОТМЕНЁН'
        case 'delivered':
            return 'ЗАКАЗ ДОСТАВЛЕН'
        default:
            return ''
    }
}

const Order = ({id, address, status, type_payment, status_payment = 1, onSelect, expanded}) => {

    const {isMobile, windowDimensions: {width}} = useWindowDimensions(650)

    console.log(status_payment)

    if (isMobile) {
        return <div>
            <div className={styles.fullOrder} onClick={onSelect}>
                <div className={styles.wrapper}>
                    <div className={styles.orderInfo}>
                        <SText style={{marginBottom: '10px'}} fontSize={16}
                               fontWeight={700}>{`ЗАКАЗ ${id}`}</SText>
                        <SText style={{marginBottom: '8px'}} fontSize={14} fontWeight={400}>{address}</SText>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                            {status !== 'canceled' && <SText style={{marginBottom: '8px'}} fontSize={14} fontWeight={700}
                                    color={'#2DCC70'}>{getTitleText(status)}</SText>}
                            {(status_payment == 0 && status !== 'canceled')  &&
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <img style={{width: 16, height: 16, marginRight: 8, marginTop: -3}} src={warning}/>
                                <SText fontSize={14} fontWeight={550}>{'Заказ не оплачен'}</SText>
                            </div>}
                            {status === 'canceled' && <SText fontSize={14} fontWeight={700} color={'#909090'}
                                                             style={{marginTop: 12}}>{'Отменён'}</SText>}
                        </div>
                    </div>
                    <div className={styles.orderStatus}>
                        <img onClick={onSelect} style={{marginTop: '-8px'}} src={open}/>
                    </div>
                </div>
            </div>
        </div>
    } else return <div className={styles.fullOrder}>
        <div onClick={onSelect} className={styles.wrapper}>
            <div className={styles.orderInfo}>
                <SText style={expanded ? {marginBottom: '25px'} : {marginBottom: '12px'}} fontSize={18}
                       fontWeight={900}>{`ЗАКАЗ ${id}`}</SText>
                {!expanded ? <SText fontSize={14} fontWeight={400}>{address}</SText> : null}
            </div>
            <div className={styles.orderStatus}>
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                    {status === 'canceled' ?
                        <SText style={{marginBottom: '15px', marginRight: 45}} fontSize={14} fontWeight={700}
                               color={'#909090'}>{'Отменён'}</SText> :
                        <SText style={{marginBottom: '15px', marginRight: 45}} fontSize={14} fontWeight={700}
                               color={'#2DCC70'}>{getTitleText(status)}</SText>}
                    {(status_payment == 0 && status !== 'canceled') &&
                    <div style={{display: 'flex', alignItems: 'center', marginRight: 46}}>
                        <SText style={{marginRight: 5}} fontSize={14} fontWeight={550}>{'Заказ не оплачен'}</SText>
                        <img style={{width: 16, height: 16}} src={warning}/>
                    </div>}
                    {false && <SText fontSize={14} fontWeight={700} color={'#909090'}>{'Отменён'}</SText>}
                </div>
                <img style={{marginTop: '-8px'}} src={expanded ? close : open}/>
            </div>
        </div>
        <UnmountClosed isOpened={expanded}>
            <div style={{paddingBottom: '18px'}}>
                <SText fontSize={28} fontWeight={900}>{getTitleText(status)}</SText>
            </div>
            <OrderIsProcessed ordersHistory={true} orderId={id}/>
        </UnmountClosed>
    </div>
}

export default ({
                    onBack = () => {
                    }
                }) => {

    const {isMobile, windowDimensions: {width}} = useWindowDimensions(650)
    const {close} = useContext(ModalContext)

    const [currentOrder, setCurrentOrder] = useState(-1)

    const {user} = useContext(DataContext)


    const orders = user.data?.orders || []

    function toggleOrder(id) {
        if (currentOrder == id)
            setCurrentOrder(-1)
        else
            setCurrentOrder(id)
    }

    if (isMobile && currentOrder !== -1)
        return <MobileSingleOrder orderId={currentOrder} onBack={() => setCurrentOrder(-1)} onClose={onBack}/>

    if (isMobile) {
        return <div className={styles.ordersWrapper}>
            <OrdersHistoryHeaderMobile title={'История заказов'}
                                       onBack={onBack}
                                       onClose={() => close('user')}
            />
            <div style={{margin: '40px 20px'}}>
                <SText fontSize={28} fontWeight={900}>{'ИСТОРИЯ ЗАКАЗОВ'}</SText>
            </div>
            {
                orders.map(order => <Order onSelect={() => toggleOrder(order.id)} {...order}/>).reverse()
            }
        </div>
    } else return <div className={styles.ordersWrapper}>
        {
            orders.map(order => <Order expanded={currentOrder == order.id}
                                       onSelect={() => toggleOrder(order.id)} {...order}/>).reverse()
        }
    </div>
}

const MobileSingleOrder = ({orderId, onBack, onClose}) => {

    const [order, setOrder] = useState(null)
    const {push} = useHistory()

    async function loadOrder() {
        const response = await getOrder(orderId)
        if (response?.order?.id) {
            setOrder(response.order)
            console.log({order: response.order})
        } else {
            push('/')
        }
    }

    useEffect(loadOrder, [orderId])

    return <div style={{paddingBottom: 20}}>
        <OrdersHistoryHeaderMobile title={'Информация о заказе'} onClose={onClose} onBack={onBack}/>
        <div style={{margin: '20px 20px'}}>
            <SText fontSize={28} fontWeight={900}>{getTitleText(order?.status)}</SText>
        </div>
        <div style={{padding: '0 20px'}}>
            <OrderIsProcessed ordersHistory orderId={orderId}/>
        </div>
    </div>
}
