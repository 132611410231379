import React, {useContext, useEffect, useState} from "react";
import SText from "./SText";
import minus from "../assets/images/minusDarkBlue.svg"
import plus from "../assets/images/plusDarkBlue.svg"
import styles from "./OrderBasket.module.css"
import garbage from "../assets/images/garbageBasket.svg"
import clearBasket from "../assets/images/clearBasket.svg"
import change from "../assets/images/change.svg"
import RoundedButton from "./RoundedButton";
import Colors from "../utils/Colors";
import Modal from "./Modal";
import Input from "./Input";
import {useCoolDown, useRestrictions, useWindowDimensions} from "../utils/hooks";
import TouchableOpacity from "./TouchableOpacity";
import closeIcon from "../assets/images/closeButton.svg";
import NewModal from "./NewModal";
import DataContext from "../data/DataContext";
import {inputFormat} from "../config";
import {between, removeYear, round} from "../utils/other";
import {NavLink, useHistory} from "react-router-dom";
import ModalContext from "../data/ModalContext";
import moment from "moment";
import tick from "../assets/images/greenTick.png"
import placeholder from '../assets/images/productImagePlaceholder.png'

const Header = ({onClose, nearestDelivery}) => {
    const {windowDimensions: {width}} = useWindowDimensions()
    const {push} = useHistory()

    if (width < 992) {
        return <div className={styles.modalHeaderBasket}>
            <div style={{display: 'flex'}}>
                <div>
                    <SText fontSize={28} fontWeight={900}>
                        КОРЗИНА
                    </SText>
                </div>
                <TouchableOpacity onClick={onClose}>
                    <div className={styles.circle}
                         style={{background: '#F5F5F5', borderRadius: '50%', width: '40px', height: '40px'}}>
                        <img src={closeIcon}/>
                    </div>
                </TouchableOpacity>
            </div>
        </div>
    } else return <div className={styles.modalHeaderBasket}>
        <div style={{display: 'flex'}}>
            <div style={{marginRight: '20px'}}>
                <SText fontSize={28} fontWeight={900}>
                    КОРЗИНА
                </SText>
            </div>
            <div>
                {nearestDelivery && <div className={styles.delivery} onClick={() => {
                    onClose()
                    push('/delivery-and-payment')
                }}>
                    <SText color={Colors.white} fontWeight={400} fontSize={16}>{'Ближайшая доставка - '}</SText>
                    &nbsp;
                    <SText
                        fontWeight={700} fontSize={16} color={Colors.white}
                        style={{borderBottom: 'solid 1px #fff'}}>{nearestDelivery}</SText>
                </div>}
            </div>
        </div>
        <TouchableOpacity onClick={onClose}>
            <div className={styles.circle}
                 style={{background: '#F5F5F5', borderRadius: '50%', width: '40px', height: '40px'}}>
                <img src={closeIcon}/>
            </div>
        </TouchableOpacity>
    </div>
}

const Position = ({...props}) => {

    const {catalogue, getProduct, cart, getProductById} = useContext(DataContext)
    const [count, setCount] = useState(props.quantity)
    const [product, setData] = useState(null)
    const {isMobile, windowDimensions: {width}} = useWindowDimensions()
    const {close} = useContext(ModalContext)


    const recordValue = useCoolDown(cart.update, 600)

    function handleCountChange(delta) {
        try {
            let _tmr = window._tmr || (window._tmr = []);
            _tmr.push({"type": "reachGoal", "id": 3220587, "goal": "addtocart"});

            window.fbq('track', 'AddToCart');

            //window.yaCounter21762586.reachGoal('add2basket')

            window.gtag('event', 'add2cart', {'event_category': 'ecommerce'});

        } catch {
        }

        let newValue = between(0, count + delta, product.availableones || 0)
        setCount(newValue)
        recordValue(product.id, newValue)
    }

    useEffect(() => {
        setCount(props.quantity)
    }, [props.quantity])


    async function loadData() {
        const product = await getProductById(props.product_id)
        setData(product)
    }

    const oldSum = product?.old_price && round(Math.max(1, count) * product?.old_price)
    const newSum = round(Math.max(1, count) * product?.price)
    const sale = Math.round(100 - newSum / oldSum * 100)

    useEffect(() => {
        loadData()
    }, [props.id])

    console.log(product)


    if (!product || count < 1) return null
    console.log({product})

    if (width < 992) {
        return <div className={styles.position}>
            <div className={styles.posInfo}>
                <NavLink onClick={() => {
                    close('cart')
                }} to={'/product/' + product.id}>
                    <div className={styles.posIcon}>
                        <img src={product.medium_image} onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = placeholder
                        }}/>
                    </div>
                </NavLink>
                <div style={{width: '100%'}}>
                    <div className={styles.posName}>
                        <NavLink onClick={() => {
                            close('cart')
                        }} to={'/product/' + product?.url}>
                            <div className={styles.productName}><SText fontSize={16}
                                                                       fontWeight={550}>{product.title}</SText></div>
                        </NavLink>
                        <div className={styles.amountSelector}>
                            {count <= 1
                                ? <div onClick={() => handleCountChange(-1)} className={styles.minus}><img
                                    src={garbage}/>
                                </div>
                                :
                                <div onClick={() => handleCountChange(-1)} className={styles.minus}><img
                                    src={minus}/>
                                </div>
                            }
                            <div><SText fontSize={16} fontWeight={500}>{count}</SText></div>
                            <div onClick={() => handleCountChange(1)} className={styles.plus}><img src={plus}/>
                            </div>
                        </div>
                    </div>

                    <div className={styles.price}>
                        <div><SText fontSize={16} fontWeight={700}
                                    color={'#909090'}>{Math.round(count * product.weight * 100) / 100 + ' кг'}</SText>
                        </div>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            {product.old_price ? <div className={styles.sale}>
                                <SText fontSize={14} fontWeight={700}
                                       color={'#909090'}>{product.old_price * count}</SText>
                                <SText fontSize={12} fontWeight={550} color={'#909090'}
                                       style={{fontWeight: 550}}>{'\u00A0₽'}</SText>
                            </div> : null}
                            <div><SText fontSize={20} fontWeight={700}>{round(props.price * count)}</SText>
                                <SText fontSize={14} fontWeight={700} style={{fontWeight: 700}}>{'\u00A0₽'}</SText>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    } else return <div className={styles.position}>
        <NavLink onClick={() => {
            close('cart')
        }} to={'/product/' + product?.url}>
            <div className={styles.posInfo}>
                <div className={styles.posIcon}><img src={product.medium_image} onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = placeholder
                }}/></div>
                <div className={styles.posName}>
                    <div className={styles.productName}><SText fontSize={20} fontWeight={500}>{product.title}</SText>
                    </div>
                    <div><SText fontSize={16} fontWeight={700}
                                color={'#909090'}>{Math.round(count * product.weight * 100) / 100 + ' кг'}</SText></div>
                </div>
            </div>
        </NavLink>
        <div className={styles.amountSelector}>
            {count <= 1
                ? <div onClick={() => handleCountChange(-1)} className={styles.minus}><img src={garbage}/></div>
                : <div onClick={() => handleCountChange(-1)} className={styles.minus}><img src={minus}/></div>
            }
            <div><SText fontSize={20} fontWeight={500}>{count}</SText></div>
            <div onClick={() => handleCountChange(1)} className={styles.plus}><img src={plus}/></div>
        </div>
        <div className={styles.price}>
            {product.old_price ?
                <div className={styles.sale}>
                    <SText fontSize={14} fontWeight={700} color={'#909090'}>
                        {product.old_price * count}
                    </SText>
                    <SText fontSize={12} fontWeight={700} color={'#909090'}
                           style={{fontWeight: 700}}>{'\u00A0₽'}</SText>
                </div> : null}
            <div><SText fontSize={20} fontWeight={700}>{round(props.price * count)}</SText> <SText fontSize={14}
                                                                                                   fontWeight={700}
                                                                                                   style={{fontWeight: 700}}>{'\u00A0₽'}</SText>
            </div>
        </div>
    </div>
}

const Footer = () => {
    const {push} = useHistory()

    const [isVisible, setIsVisible] = useState(false)
    const [money, setMoney] = useState('')
    const [discount, setDiscount] = useState('')

    const {cart, catalogue, auth} = useContext(DataContext)
    const {close} = useContext(ModalContext)

    const bonus = cart.data?.amount_bonuses


    function _onSubmit() {
        try {
            let _tmr = window._tmr || (window._tmr = []);
            _tmr.push({"type": "reachGoal", "id": 3220587, "goal": "initpurchase"});

            window.fbq('track', 'InitiateCheckout');

            //window.yaCounter21762586.reachGoal('clickbeginorder')

        } catch {
        }

        push('/submit_order')
    }

    /*    const getMoneyBeforeDiscount = (basketAmount) => {
            if (basketAmount + bonus < 7000 && 7000 - basketAmount + bonus <= 800 && 7000 - basketAmount + bonus > 0 ) {
                setMoney(7000 - basketAmount + bonus)
                setDiscount(500)
            } else if (basketAmount + bonus < 10000 && 10000 - basketAmount + bonus <= 800 && 10000 - basketAmount + bonus > 0) {
                setMoney(10000 - basketAmount + bonus)
                setDiscount(1000)
            } else if (basketAmount + bonus >= 7000 && 10000 - basketAmount + bonus > 800) {
                setDiscount(500)
            }
        }

        useEffect(() => {
            getMoneyBeforeDiscount(cart.data?.basketAmount)
        }, [cart.data?.basketAmount])*/

    const threshold = catalogue.data?.threshold - (cart.data?.amount_bonuses || 0)

    const {windowDimensions: {width}} = useWindowDimensions()

    const onSubmit = useRestrictions(_onSubmit)

    if (width < 992) {
        return <div className={styles.footer}>

            {auth.data && <>
                <div className={styles.promo}>
                    <div style={{marginRight: 20}} onClick={() => setIsVisible(true)}><SText fontSize={18}
                                                                                             fontWeight={700}>Промокод</SText>
                    </div>
                    <SText fontSize={12} fontWeight={550}
                           lineHeight={12}>{cart.data.desc ? cart.data.desc : null}</SText>
                    <div style={{marginLeft: 20}} onClick={() => setIsVisible(true)}><img style={{height: 20}}
                                                                                          src={cart.data.promocode ? tick : change}/>
                    </div>
                </div>

                <div style={{padding: '0', margin: '0 auto 22px'}} className={styles.line}/>
            </>}

            <div className={styles.footerPrice}>
                <div style={{marginRight: '20px'}}><SText fontSize={20} fontWeight={900}>Итог</SText></div>
                <div>
                    <SText fontSize={20} fontWeight={900}>{round(cart.data?.basketAmount || 0)}</SText>
                    <SText fontSize={14} fontWeight={700} style={{fontWeight: 900}}>{'\u00A0₽'}</SText>
                </div>
            </div>

            {cart.data.basketAmount < threshold && <div style={{marginBottom: '15px', textAlign: 'right'}}>
                <SText fontSize={12} fontWeight={550} color={'#FFA353'}>Минимальная сумма для оформления <br/> заказа
                    - {threshold} руб</SText>
            </div>}
            {/*            {(cart.data?.basketAmount + bonus >= 6200 && cart.data?.basketAmount + bonus < 7000) || (cart.data?.basketAmount + bonus >= 9200 && cart.data?.basketAmount + bonus < 10000)
                ? <div style={{marginBottom: '15px', textAlign: 'right'}}><SText fontSize={12} fontWeight={550}
                                                                                 lineHeight={14}>{`Осталось ${money} руб до скидки ${discount} руб на заказ!`}</SText>
                </div>
                : cart.data?.basketAmount + bonus >= 10000 || (cart.data?.basketAmount + bonus >= 7000 && cart.data?.basketAmount + bonus < 9200)
                    ? <div style={{marginBottom: '15px', textAlign: 'right'}}><SText fontSize={12} fontWeight={550}
                                                                                     lineHeight={14}>{`Скидка ${discount} руб на заказ`}</SText>
                    </div>
                    : null}*/}
            {cart.data.amount_bonuses !== 0 &&
            <div style={{marginBottom: '15px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}><img
                src={tick} style={{height: 20}}/> <SText style={{marginLeft: 20}} fontSize={16} fontWeight={550}
                                                         lineHeight={18}>{`Скидка ${cart.data.amount_bonuses} рублей на заказ`}</SText>
            </div>}

            <div style={{display: 'flex', justifyContent: 'flex-end',}}>
                <div style={{margin: width <= 375 ? '0 auto' : 0}} className={styles.orderingButton}>
                    <RoundedButton activeColor={Colors.mainGreen}
                                   onPress={() => {
                                       window.awaitingOrder = true
                                       close('cart')
                                       onSubmit()
                                   }}
                                   dimmedColor={'#96E6B8'}
                                   label={'Оформить заказ'}
                                   disabled={cart.data.basketAmount < threshold}/>
                </div>
            </div>

            <NewModal onClose={() => setIsVisible(false)} isVisible={isVisible} modalTitle={'ПРОМОКОД'} minWidth={303}
                      maxHeight={323}
                      fullScreen={false}
                      maxWidth={303}>
                <PromoModal onClose={() => setIsVisible(false)}/>
            </NewModal>

            {/*            <Modal isVisible={isVisible} onClose={() => setIsVisible(false)} modalTitle={'ПРОМОКОД'} maxHeight={323}
                   maxWidth={303}>
                <PromoModal/>
            </Modal>*/}

        </div>
    } else return <div className={styles.footer}>
        <div className={styles.payment}>
            {auth.data ? <>
                <div className={styles.promo}>
                    <div onClick={() => setIsVisible(true)} style={{marginRight: '25px'}}><SText fontSize={18}
                                                                                                 fontWeight={700}>Промокод</SText>
                    </div>
                    <div style={{marginRight: '25px'}} onClick={() => setIsVisible(true)}><img
                        src={cart.data.promocode ? tick : change} style={{height: 20}}/></div>
                    {cart.data.desc ?
                        <SText fontSize={14} fontWeight={550} lineHeight={12}>{cart.data.desc}</SText> : null}
                </div>
            </> : <div/>}
            <div className={styles.footerPrice}>
                <div style={{marginRight: '20px'}}><SText fontSize={18} fontWeight={400}>Итого:</SText></div>
                <div>
                    <SText fontSize={28} fontWeight={700}>{round(cart.data?.basketAmount || 0)}</SText>
                    <SText fontSize={16} fontWeight={700} style={{fontWeight: 900}}>{'\u00A0₽'}</SText>
                </div>
            </div>
        </div>

        <Modal isVisible={isVisible} onClose={() => setIsVisible(false)} isPromo modalTitle={'ПРОМОКОД'} maxHeight={350}
               maxWidth={475}>
            <PromoModal onClose={() => setIsVisible(false)}/>
        </Modal>

        <div className={styles.ordering}>
            <div>
                <SText fontSize={16} fontWeight={550} color={'#FFA353'}>{cart.data.basketAmount < threshold ?
                    'Минимальная сумма для оформления заказа - ' + threshold + ' руб' :
                    ''}</SText>

                {/*                {(cart.data?.basketAmount >= 6200 && cart.data?.basketAmount < 7000) || (cart.data?.basketAmount >= 9200 && cart.data?.basketAmount < 10000)
                    ? <div><SText fontSize={16} fontWeight={550}
                                  lineHeight={18}>{`Осталось ${money} руб до скидки ${discount} руб на заказ!`}</SText>
                    </div>
                    : cart.data?.basketAmount >= 10000 || (cart.data?.basketAmount >= 7000 && cart.data?.basketAmount < 9200)
                        ? <div><SText fontSize={16} fontWeight={550}
                                      lineHeight={18}>{`Скидка ${discount} руб на заказ`}</SText>
                        </div>
                        : null}*/}
                {cart.data.amount_bonuses !== 0 &&
                <div style={{display: 'flex', alignItems: 'center'}}><SText fontSize={16} fontWeight={550}
                                                                            lineHeight={18}
                                                                            style={{marginRight: 20}}>{`Скидка ${cart.data.amount_bonuses} рублей на заказ`}</SText>
                    <img src={tick} style={{height: 20}}/></div>}
            </div>
            <div className={styles.orderingButton}>

                <RoundedButton activeColor={Colors.mainGreen}
                               dimmedColor={'#96E6B8'}
                               onPress={() => {
                                   window.awaitingOrder = true
                                   close('cart')
                                   onSubmit()
                               }}
                               label={'Оформить заказ'}
                               disabled={cart.data.basketAmount < threshold}/>
            </div>
        </div>
    </div>
}

export const PromoModal = ({onClose}) => {

    const {isMobile} = useWindowDimensions(991)
    const {cart} = useContext(DataContext)
    const [value, setValue] = useState(cart.data?.promocode || '')
    const {appendPromoCode, removePromoCode} = useContext(DataContext)
    const [error, setError] = useState('')

    async function onSubmit() {
        if (cart.data.promocode !== '') {
            await removePromoCode()
        }
        if (value !== '') {
            const response = await appendPromoCode(value)
            debugger
            console.log({response})
            if (response.verified)
                onClose()
            else {
                response.message ? setError(response.message) : setError('Промокод неверный.\nПожалуйста, попробуйте еще раз')
            }
        } else {
            const response = await removePromoCode()
            onClose()
        }
    }

    if (isMobile) {
        return <div>
            <div>
                <div className={styles.promoHeader}>
                    <SText fontSize={16} fontWeight={900}>ПРОМОКОД</SText>
                </div>
                <TouchableOpacity onClick={onClose}>
                    <div className={styles.circlePromo}
                         style={{background: '#F5F5F5', borderRadius: '50%', width: '26px', height: '26px'}}>
                        <img src={closeIcon}/>
                    </div>
                </TouchableOpacity>
            </div>
            <div style={{padding: '0 15px'}}>
                <div style={{marginBottom: '40px'}}><SText fontSize={14} fontWeight={550}>Впишите в строке ниже ваш
                    промокод</SText></div>
                <div className={styles.promoInput}>
                    <Input placeholder={'Промокод'} value={value} onChange={(text) => setValue(text.toUpperCase())}/>
                </div>
                {error !== '' &&
                <div style={{marginBottom: 30, maxWidth: 263}}><SText div fontSize={14} fontWeight={700} lineHeight={15}
                                                                      color={'#ff5252'}>{error}</SText>
                </div>}
            </div>
            <div style={{display: 'flex', justifyContent: 'center', width: '100%', marginBottom: 24}}>
                <div className={styles.promoButton}><RoundedButton label={'Активировать'} onPress={onSubmit}/></div>
            </div>
        </div>
    } else return <div>
        <div>
            <div style={{marginBottom: '40px'}}><SText fontSize={18} fontWeight={550}>Впишите в строке ниже ваш
                промокод</SText></div>
            <div style={error ? {marginBottom: 14} : {marginBottom: 70}} className={styles.promoInput}>
                <Input placeholder={'Промокод'} value={value} onChange={(text) => setValue(text.toUpperCase())}/>
            </div>
            {error !== '' && <div style={{marginBottom: 30}}><SText div fontSize={14} fontWeight={700} lineHeight={15}
                                                                    color={'#ff5252'}>{error}</SText>
            </div>}
        </div>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
            <div className={styles.promoButton}><RoundedButton label={'Активировать'} onPress={onSubmit}/></div>
        </div>
    </div>
}

const ClearModal = ({setVisible}) => {

    const {cart, removePromoCode} = useContext(DataContext)

    async function onClear() {
        await cart.remove()
        if (cart.data.promocode !== '') {
            debugger
            await removePromoCode()
        }
        setVisible(false)
    }

    return <div style={{padding: '20px', maxWidth: 400}}>
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <div className={styles.clearModal}><img src={clearBasket}/></div>
        </div>
        <div style={{marginBottom: '18px', textAlign: 'center', lineHeight: '20px'}}><SText fontSize={18}
                                                                                            fontWeight={700}>Вы уверены,
            что
            хотите удалить все товары из вашей корзины?</SText></div>
        <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
            <RoundedButton
                onPress={() => setVisible(false)}
                containerStyle={{width: '259px', height: '50px', marginBottom: '24px'}}
                label={'Отмена'}
                activeColor={Colors.orange}/>
        </div>
        <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
            <RoundedButton containerStyle={{width: '259px', height: '50px'}} label={'Да, удалить товары'}
                           onPress={onClear}/>
        </div>
    </div>
}

const OrderBasket = ({onClose, height}) => {

    const [isVisible, setIsVisible] = useState(false)
    const {windowDimensions: {width}} = useWindowDimensions()

    const {cart, deliverySlots, auth} = useContext(DataContext)

    const positions = cart.data?.productList || []

    const {end} = deliverySlots.data?.slots[0] || {}

    const nearestDelivery = end ? removeYear(moment(end, inputFormat).format('LLL')) : false

    const {windowDimensions: {height: max_height}} = useWindowDimensions()

    console.log({max_height})

    useEffect(() => {

        if (!auth.data?.auth) {
            const productList = cart?.data?.productList.map(prod => ({
                product: {
                    ids: {
                        sMProductID: `${prod.product_id}`
                    }
                },
                count: `${prod.quantity}`,
                pricePerItem: `${prod.price}`
            }))

            window.mindbox("async", {
                operation: "SetKorzinaItemList",
                data: {
                    productList: productList
                }
            });
        }

    }, [])


    console.log(cart)

    if (width < 992) {
        return <div style={{maxHeight: positions.length > 4 ? Math.min(880, max_height) : 660}}>
            <Header onClose={onClose}/>
            {positions.length > 0
                ? <div>
                    {positions.map(pos => <Position {...pos} key={pos?.product_id || Math.random()}/>)}
                    <div className={styles.clearBasket}>
                        <div onClick={() => setIsVisible(true)} className={styles.clearIcon}><img src={clearBasket}/>
                        </div>
                        <div onClick={() => setIsVisible(true)}><SText fontSize={14} fontWeight={400} color={'#909090'}>Очистить
                            корзину</SText></div>
                    </div>
                    <div className={styles.line}/>
                    <NewModal margin={"0 20px"} isVisible={isVisible} onClose={() => setIsVisible(false)}
                              fullScreen={false}>
                        <ClearModal setVisible={() => setIsVisible(false)}/>
                    </NewModal>
                    <Footer/>
                </div>
                : <div className={styles.emptyCart}>
                    <SText style={{
                        textAlign: 'left',
                        alignSelf: 'flex-start',
                        paddingLeft: '20px',
                        textTransform: 'uppercase'
                    }} fontSize={24}
                           fontWeight={900}>в вашей корзине <br/> пока что
                        нет <br/> товаров</SText>
                    <div style={{width: '306px', height: '50px'}}><RoundedButton label={'К покупкам'}
                                                                                 onPress={onClose}/></div>
                </div>
            }
        </div>
    } else return <div style={{
        maxHeight: positions.length > 4 ? Math.min(880, max_height) : Math.min(660, max_height),
        minHeight: 660
    }}>
        <Header onClose={onClose} nearestDelivery={nearestDelivery}/>
        {positions.length > 0
            ? <div style={{padding: '0 50px'}}>
                {positions.map(pos => <Position {...pos} key={pos?.product_id || Math.random()}/>)}

                <div className={styles.clearBasket}>
                    <div onClick={() => setIsVisible(true)} className={styles.clearIcon}>
                        <img src={clearBasket}/>
                    </div>
                    <div onClick={() => setIsVisible(true)}><SText fontSize={14} fontWeight={400} color={'#909090'}>Очистить
                        корзину</SText></div>
                </div>
                <div className={styles.line}/>
                <NewModal margin={"0 20px"} isVisible={isVisible} onClose={() => setIsVisible(false)}
                          fullScreen={false}>
                    <ClearModal setVisible={() => setIsVisible(false)}/>
                </NewModal>
                <Footer/>
            </div>
            : <div className={styles.emptyCart}>
                <SText style={{textAlign: 'center', textTransform: 'uppercase'}} fontSize={20} fontWeight={900}>в вашей
                    корзине пока что
                    нет <br/> товаров</SText>
                <div style={{width: '306px', height: '50px'}}><RoundedButton label={'К покупкам'} onPress={onClose}/>
                </div>
            </div>
        }

    </div>
}

export default OrderBasket
