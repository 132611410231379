import React, {useContext, useEffect, useRef, useState} from "react";
import styles from "./Search.module.css";
import {text} from "../utils/mixins";
import {useHistory} from "react-router-dom";
import SText from "./SText";
import DataContext from "../data/DataContext";
import useSearch from "../data/SearchContext";
import {handleEnter} from "../utils/other";
import search from "../assets/images/search.svg"
import clear from "../assets/images/closeButton.svg"
import stick from "../assets/images/stick.svg"

////////
import TouchableOpacity from "./TouchableOpacity";
import Colors from "../utils/Colors";
import {ProductCard} from "./ProductCard";


export const SearchInput = () => {
    const {query, onQueryChange, show, hide, popupVisible} = useSearch()
    const {push} = useHistory()

    const input = useRef(null)

    function onEnter() {
        push('/search?query=' + query)
        hide()
    }

    useEffect(() => {
        if (!popupVisible)
            input.current?.blur()
    }, [popupVisible])


    return <div className={styles.popupWrapper}>
        <input
            className={styles.input}
            ref={input}
            style={text(18, 700, 2)}
            placeholder={"Я ищу"}
            value={query}
            onKeyPress={handleEnter(onEnter)}
            onClick={show}
            onChange={e => onQueryChange(e.target.value)}
        />
        <div className={styles.buttons}>
            <img onClick={() => {
                onQueryChange('')
                hide()
            }
            } draggable={false} style={{
                visibility: query !== '' ? 'visible' : 'hidden',
                marginRight: 11,
                opacity: 0.4,
                marginTop: -1,
                cursor: 'pointer'
            }} src={clear}/>
            <img draggable={false} style={{marginRight: 14}} src={stick}/>
            <img draggable={false} style={{cursor: 'pointer'}} onClick={onEnter} src={search}/>
        </div>
    </div>
}

const AutoCompleteSuggestion = ({text = ''}) => {
    const {onQueryChange} = useSearch()
    return <TouchableOpacity className={styles.autoCompleteSuggestion} onClick={() => onQueryChange(text)}>
        <SText fontSize={14} fontWeight={700}>
            {text}
        </SText>
    </TouchableOpacity>
}

export const SearchPopupDesktop = ({mt = 100}) => {
    const {suggestions, onQueryChange, popupVisible, hide, clearHistory, query} = useSearch()
    const [count, setCount] = useState(0)
    const {push} = useHistory()

    function onEnter() {
        push('/search?query=' + query)
        hide()
    }

    function handleEscape(e) {
        if (e.keyCode === 27)
            hide()
    }


    const listener = (e) => {
        if (document.querySelector('.' + styles.popupContainer)?.contains(e.target) ||
            document.querySelector('.' + styles.input)?.contains(e.target)
        ) {
        } else {
            hide()
        }
    }

    useEffect(() => {
        window.addEventListener('click', listener)
        window.addEventListener('keydown', handleEscape)
        return () => {
            window.removeEventListener('click', listener)
            window.removeEventListener('keydown', handleEscape)
        }
    }, [])

    useEffect(() => {
        setCount(0)
    }, [suggestions.products])

    const show = popupVisible && (/*suggestions.history.length || */query != '')

    if (!show) return null

    return <div className={styles.popupContainer} style={{top: mt}}>
        <div className={styles.popupContent}>
{/*            <div className={styles.searchSelector}>
                {!!suggestions.autocomplete.length && <div style={{marginBottom: 40}}>
                    {suggestions.autocomplete.map(item => (
                        <AutoCompleteSuggestion text={item}/>
                    ))}
                </div>}
                {suggestions.categories && false && <div>
                    <SText fontWeight={700} fontSize={14}>{'КАТЕГОРИИ'}</SText>
                    <div>
                        {suggestions.categories?.map(category => <div>
                            <SText>{category?.name}</SText>
                        </div>)}
                    </div>
                </div>}

                {!!suggestions.history.length && <div>
                    <div style={{display: 'flex', padding: '9px 23px', alignItems: 'center'}}>
                        <SText fontWeight={700} fontSize={14} style={{marginRight: 12}}>
                            {'ИСТОРИЯ ПОИСКА'}
                        </SText>
                        <TouchableOpacity onClick={clearHistory}>
                            <SText fontSize={14} fontWeight={400} color={'#c4c4c4'}>
                                {'Очистить'}
                            </SText>
                        </TouchableOpacity>
                    </div>
                    <div>
                        {suggestions.history?.map(item => <TouchableOpacity className={styles.autoCompleteSuggestion}
                                                                            onClick={() => onQueryChange(item)}>
                            <SText fontSize={14} fontWeight={400}>
                                {item}
                            </SText>
                        </TouchableOpacity>)}
                    </div>
                </div>}

            </div>*/}
            <div className={styles.resultsContainer}>
                <div className={styles.searchResults} id={'workaround'}>
                    {suggestions.products.map(product => {
                        return <EcoProduct ecoId={product.id} key={product.id} setCount={setCount}/>
                    })}
                </div>
                {count > 0 && <div className={styles.popupBottomLine}>
                    <TouchableOpacity className={styles.seeAll} onClick={onEnter}>
                        <SText fontSize={16} fontWeight={900} color={Colors.darkBlue}>{'Посмотреть все >>'}</SText>
                    </TouchableOpacity>
                </div>}
            </div>
        </div>
    </div>
}

const EcoProduct = ({
                        ecoId, setCount = () => {
    }
                    }) => {
    const [product, setProduct] = useState(null)

    const {getProductByEcoId} = useContext(DataContext)

    const loadProduct = async () => {
        const product = await getProductByEcoId(ecoId)
        if (product)
            setCount(prev => prev + 1)
        setProduct(product)
    }

    useEffect(() => {
        loadProduct()
    }, [ecoId])

    if (!product || isNaN(product.price) ||  product?.available === '0') return null

    return <ProductCard id={product.id}/>
}

export default SearchInput
