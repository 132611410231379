import React, {useState} from 'react'
import styles from './Input.module.css'
import cn from "classnames";

const Input =({className, style={}, focus, onChange = ()=>{}, isSaveDisabled=false, required=false, value, title, error=false, color='#909090', onSubmit=()=>{}, ...props}) => {

    const [isInFocus, setInFocus] = useState(false)

    const _onChange = (e) => {
        onChange(e.target.value)
    }

    const _handleSubmit = (e) => {
        if (e.key === 'Enter' && !isSaveDisabled) {
            onSubmit()
            setInFocus(false)
        }
    }

    const getBorderColor = () => {
        if (props.disabled) return '#d2d2d2'
        else {
            if (isInFocus) return '#EDEDED'
            else return error ? 'red' : '#EDEDED'
        }
    }
    const getTitleColor = () => {
        if (props.disabled) return 'black'
        else {
            if (isInFocus)
                return color
            else
                return error ? 'red' : '#909090'
        }
    }

    const isStarVisible = required && !props.disabled
    return <div className={cn(styles.inputWrapper, props.wrapperStyle)}>
        {title && <div className={styles.title}
                       style={{
                           opacity: (isInFocus && !value ) || value !== '' ? 1 : 0,
                           transform:  `translateY(${(isInFocus && !value ) || value !== '' ? '0' : '5px'})`,
                           color: getTitleColor()
                       }}
        >
            {title || props.placeholder || ''}
            <div className={styles.requiredStar} style={{transform: `scale(${isStarVisible ? 1 : 0})`}}>*</div>
        </div>}
        <input className={cn(className, styles.input)}
               style={{...style,borderBottomColor: getBorderColor()}}
               onChange={_onChange}
               placeholder={title+(required && !props.disabled ? ' *' : '')}
               onFocus={()=>{

                   setInFocus(true)}
               }
               onBlur={()=>{

                   setInFocus(false)}}
               onKeyDown={_handleSubmit}
               value={value}
               {...props}
        />
    </div>
}

export const Select = ({options=[], onSelect=()=>{}, title=''}) =>{

    const _options = options.map((option, index)=>({...option, index}))

    const handleSelect = (e) => {
        const selectedIndex = e.target?.value
        const option = _options.find(({index})=>Number(index)===Number(selectedIndex))
        console.log(option)
        onSelect(option)
    }

    return <div className={styles.inputWrapper}>
        {title && <div className={styles.title}>
            {title || ''}
        </div>
        }
        <select className={styles.select} onChange={handleSelect}>
        {_options.map(({name, index})=><option value={index}>{name}</option>)}
    </select>
    </div>
}

export default Input
