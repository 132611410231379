import React from "react";
import {NavLink} from "react-router-dom";
import SText from "./SText";
import {insertBetween} from "../utils/other";
import {useWindowDimensions} from "../utils/hooks";


const BreadCrumbs = ({_items=[]}) => {
    const items = [{name: 'Главная', to: '/'}, ..._items]

    return <div style={{display: 'flex', marginBottom: 30, paddingTop: 30, flexWrap: 'wrap'}}>
        {insertBetween(items.map(item=><NavLink to={item.to}>
            <SText style={{marginRight: 8}} fontSize={14} fontWeight={550} color={'#d9d9d9'}>
                {item.name}
            </SText>
        </NavLink>), <SText style={{marginRight: 8}} fontSize={14} fontWeight={550} color={'#d9d9d9'}>{'/'}</SText>)}
    </div>
}


export default BreadCrumbs
