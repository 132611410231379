import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import styles from './HeaderBurger.module.css'
import SText from "./SText";
import whatsapp from "../assets/images/whatsapp.svg";
import DataContext from "../data/DataContext";
import ModalContext from "../data/ModalContext";
import { useRestrictions } from "../utils/hooks";
import { DOMAIN_URL } from "../config";

const HeaderBurger = ({
    onClose = () => {
    }
}) => {

    const { catalogue, user, auth } = useContext(DataContext)
    const { open: _open } = useContext(ModalContext)

    const open = useRestrictions(_open)

    const onCategoryClick = (id) => {
        onClose()
        if (!auth.data?.auth) {
            window.mindbox("async", {
                operation: "OnlineCategoryView",
                data: {
                    viewProductCategory: {
                        productCategory: {
                            ids: {
                                sMProductID: id
                            }
                        }
                    }
                }
            });
        }
    }

    const categories = catalogue.data?.categories || []

    return <div className={ styles.wrapper }>
        <div className={ styles.user } onClick={ () => {
            open('user')
            onClose()
        } }>
            {/*<div className={styles.aboutImg}><img src={zaglushka}/></div>*/ }
            <div className={ styles.aboutName }>
                <div><SText fontSize={ 22 } fontWeight={ 900 }>{ user.data?.full_name || '' }</SText></div>
                <div><SText fontSize={ 16 } fontWeight={ 550 }>{ 'Личный кабинет' }</SText></div>
            </div>
        </div>
        <div className={ styles.categories }>
            {
                categories.map(category => <NavLink to={ '/catalog/' + category.url + '/' + category.subCategories[0]?.url } onClick={ () => {
                    onCategoryClick(category.id)
                } }>
                    <div className={ styles.category }>
                        <div className={ styles.categoryIcon }>
                            <img src={ DOMAIN_URL + category?.image_mob } />
                        </div>
                        <div className={ styles.categoryName }>
                            <SText fontSize={ 11 } fontWeight={ 700 }>
                                { category.name }
                            </SText>
                        </div>
                    </div>
                </NavLink>)
            }
        </div>
        <div className={ styles.info }>
            <NavLink onClick={ () => window.scrollTo(0, 0) } to={ "/delivery-and-payment" }><SText fontSize={ 16 }
                fontWeight={ 550 }>{ 'Доставка и оплата' }</SText></NavLink>
            {/*            <SText fontSize={16} fontWeight={550}>{'Поставщики'}</SText>*/ }
            <NavLink onClick={ () => window.scrollTo(0, 0) } to={ "/about-season-market" }><SText fontSize={ 16 } fontWeight={ 550 }>{ 'О нас' }</SText></NavLink>
            {/*            <SText fontSize={16} fontWeight={550}>{'Контакты'}</SText>*/ }
        </div>
        <div className={ styles.contacts }>
            <SText style={ { marginBottom: 25 } } fontSize={ 14 } fontWeight={ 500 } color={ '#909090' }>{ 'Соглашения и правила' }</SText>
            <a style={ { marginBottom: 18 } } href="tel:+74952155144"><SText fontSize={ 16 } fontWeight={ 550 } style={ {
                borderBottom: 'solid 1px #213140',
                width: 'fit-content'
            } }>{ '+7 (495) 215-51-44' }</SText></a>
            {/*            <a style={{marginBottom: 18}} target="_blank" href="https://wa.me/79771260296"><SText style={{marginBottom: '11px'}}>
                <SText style={{paddingRight: '8px'}}><img src={whatsapp}/></SText>
                <SText fontSize={16} fontWeight={550} style={{
                    borderBottom: 'solid 1px #213140',
                    width: 'fit-content'
                }}>{'+7 (977) 126-02-96'}</SText>
            </SText></a>*/}
            <a style={ { marginBottom: 18 } } target="_blank" href="https://wa.me/79660363433"><SText style={ { marginBottom: '11px' } }>
                <SText style={ { paddingRight: '8px' } }><img src={ whatsapp } /></SText>
                <SText fontSize={ 16 } fontWeight={ 550 } style={ {
                    borderBottom: 'solid 1px #213140',
                    width: 'fit-content'
                } }>{ '+7 (495) 215-51-44' }</SText>
            </SText></a>
            <SText fontSize={ 14 } fontWeight={ 550 } color={ '#909090' }>{ 'ежедневно с 8:00 до 20:00' }</SText>
        </div>
    </div>
}

export default HeaderBurger
