import React from "react";
import styles from "./BonusSystem.module.css"
import {Helmet} from "react-helmet";
import Header from "./Header";
import MobileExtraInfo from "../screens/Catalogue/components/MobileExtraInfo";
import {useWindowDimensions} from "../utils/hooks";
import BreadCrumbs from "./BreadCrumbs";
import SText from "./SText";
import gift from "../assets/images/giftVoucher.jpg"

export default () => {

    const {windowDimensions: {width}} = useWindowDimensions(600)

    const breadCrumbs = [
        {
            name: 'Электронные сертификаты',
            to: '/gift-voucher'
        }
    ]

    return <div>
        <Helmet>
            <title>Электронные сертификаты</title>
            <meta name="description" content='Электронные сертификаты'/>
            <meta name="keywords" content='Электронные сертификаты'/>
        </Helmet>
        <Header/>
        {width < 992 && <MobileExtraInfo/>}
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <BreadCrumbs _items={breadCrumbs}/>

                <div style={{marginBottom: width > 762 ? 28 : 18}}><img style={{width: "100%", maxWidth: 532}} src={gift}/></div>


                <div style={{marginBottom: width > 762 ? 28 : 18}}><SText fontSize={16} fontWeight={550}
                                                                          lineHeight={18}>Электронные сертификаты SeasonMarket —
                    идеальный выбор на любой праздник, по поводу и без. Радуйте родных, удивляйте друзей, поздравляйте
                    коллег!</SText></div>

                <div style={{marginBottom: width > 762 ? 22 : 42}}><SText fontSize={16} fontWeight={900}
                                                                          lineHeight={15}>Условия применения:</SText>
                </div>

                <div className={styles.dot} style={{marginBottom: width > 762 ? 27 : 22}}><SText fontSize={16}
                                                                                                 fontWeight={550}
                                                                                                 lineHeight={18}>Подарочный
                    сертификат предоставляет владельцу право приобретения товаров на сумму, эквивалентную номиналу
                    подарочного сертификата.</SText></div>
                <div className={styles.dot} style={{marginBottom: width > 762 ? 27 : 22}}><SText fontSize={16}
                                                                                                 fontWeight={550}
                                                                                                 lineHeight={18}>При
                    оплате подарочным сертификатом товаров, общей стоимостью ниже его номинала, разница в денежном
                    эквиваленте не компенсируется.</SText></div>
                <div className={styles.dot} style={{marginBottom: width > 762 ? 27 : 22}}><SText fontSize={16}
                                                                                                 fontWeight={550}
                                                                                                 lineHeight={18}>При
                    оплате подарочным сертификатом товаров, общая стоимость которых превышает номинал подарочного
                    сертификата, покупатель доплачивает оставшуюся сумму.</SText></div>
                <div className={styles.dot} style={{marginBottom: width > 762 ? 27 : 22}}><SText fontSize={16}
                                                                                                 fontWeight={550}
                                                                                                 lineHeight={18}>Сертификат
                    действует в течение года со дня покупки.</SText></div>
                <div className={styles.dot} style={{marginBottom: width > 762 ? 30 : 30}}><SText fontSize={16}
                                                                                                 fontWeight={550}
                                                                                                 lineHeight={18}>Введите
                    промокод при оформлении заказа в поле «Промокод».</SText></div>
            </div>
        </div>
    </div>
}