import React from "react";
import SText from "./SText";
import back from "../assets/images/backMobileHeader.svg"
import TouchableOpacity from "./TouchableOpacity";
import styles from './OrdersHistoryHeaderMobile.module.css'
import close from "../assets/images/closeButton.svg";


export default ({onBack=()=>{}, onClose=()=>{}, title}) => {

    return <div className={styles.grid}>
        <TouchableOpacity className={styles.touchableArea} onClick={onBack}>
            <div className={styles.circle}>
                <img src={back}/>
            </div>
        </TouchableOpacity>
        <div className={styles.headerTitle}>
            <SText fontSize={16} fontWeight={700}>{title}</SText>
        </div>
        <TouchableOpacity className={styles.touchableArea} onClick={onClose}>
            <div className={styles.circle}>
                <img src={close}/>
            </div>
        </TouchableOpacity>
    </div>
}
